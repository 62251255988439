import React from 'react';
import { usePhrasing } from "../hooks/useSkuUtils";
import * as Constants from '../constants';
import { RxChatBubble } from "react-icons/rx";
import { useTranslation } from "react-i18next";

export const useFaqContent = () => {
  const { t } = useTranslation();
  const {
    getAudioText, headingPlusPlayAudio,
  } = usePhrasing();

  const SwaOverviewContent = {
    title: <>
  {t('global.label.shopWeAll')}{Constants.TM_SYM}&nbsp;{t('faq.overview.label')}
  {/* ShopWeAll&#8482; Overview */}
    </>,
    rows: [
      {
        title: <>
          {t('faq.whatIsShopWeAll.title010')}
          {t('faq.whatIsShopWeAll.title020')}
          {Constants.TM_SYM}
          {t('faq.whatIsShopWeAll.title030')}
          {/*
          "What is ShopWeAll™?"
          */}
        </>,
        content: <>
          <p>
            {t('faq.whatIsShopWeAll.content010')}
            {/*
            ShopWeAll is a Shopping List tool for groups, to buy in bulk and split the purchases.
            It works as a traditional shopping list in which you specify what you need, and the person(s) who goes shopping (or takes delivery) gets it for you.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShopWeAll.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShopWeAll.content020')}
            {/*
            Less conventionally, as in a co-op, you might request only a portion of an item and you look for others in your Circle to order the rest of the item.
            Basically, this lets the group buy a large package of something (at a better unit-price) based on pre-arranging the amount that each of you want.
            If you all don't have enough collective interest in purchasing an item, you will likely skip buying it on this Trip.
            This process plays out for all the items on your list.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShopWeAll.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShopWeAll.content030')}
            {/*
            Maybe you use ShopWeAll to combine your family's purchasing power with other families, or your business with other businesses.
            Maybe you use the basic power of a shopping list to manage traditional shopping for/with a friend(s), and you don't bother with subdividing the items.
            Working together for mutual benefit is rewarding in itself.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShopWeAll.content030')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.whatIsShoppingCircle.title010')}
          {t('faq.whatIsShoppingCircle.title020')}
          {/*
          What is a Shopping Circle? How to make one? How does it work?
          */}
        </>,
        content: <>
          <p>
            {t('faq.whatIsShoppingCircle.content010')}
            {/*
            "Shopping Circle" is our term for the group of people (or families, businesses, etc.) - "Shoppers" - that combine forces to purchase in volume.
            It comes to life when someone sets up a Circle by clicking the 'Add Circle' button after clicking the 'Circles' tab.
            That person becomes the Circle's Leader and its first Administrator by going through the setup steps.
            Only the Leader may grant/suspend Admin powers to other Shoppers in the Circle.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShoppingCircle.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShoppingCircle.content020')}
            {/*
            The Shopping Circle grows when an admin invites other Shoppers to join and they accept.
            Admins create invitations via a Circle's "Details" page.
            This requires learning the Shopper's Username - something that happens outside of the ShopWeAll system.
            (You know each other, right?
            And can trust them?)
            So in a previous conversation/text/etc you have already decided to try this.
            The other person sets up an account, tells you what Username they chose, and now you can add them to your Circle.
            Shoppers can see and respond to invitations via their account's 'Home' tab.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShoppingCircle.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShoppingCircle.content025')}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShoppingCircle.content025')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShoppingCircle.content030')}
            {/*
            A Shopper can remove themself from a Circle by visiting the Circle via the 'Circles' Navigation button and then activating the 'Remove' controls.
            If the Shopper clicks the 'Remove' button that appears, this will trigger three 'Delete' actions:
            It will remove any existing invitations for the Shopper, it removes any and all Orders the Shopper has ever made for the Circle, and then it removes the Shopper's membership in the Circle.
            A Circle's Leader also has the unrestricted power to remove a Shopper from the Circle.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShoppingCircle.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShoppingCircle.content040')}
            {/*
            For shopping, an administrator for the group picks a time and date for a shopping trip.
            Then, members of the Shopping Circle add items to the Trip's shopping list (if not already there) and indicate what percentage or portion of the item they would like.
            These "orders" register Shoppers' interest in purchasing something.
            They are not official communications to a store or service to buy or deliver the item(s).
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShoppingCircle.content040')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShoppingCircle.content050')}
            {/*
            Before going shopping, the Circle firms up the list by deciding which items have enough group interest to justify purchasing.
            It's up to the Shopping Circle to decide how to do this.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShoppingCircle.content050')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatIsShoppingCircle.content060')}
            <em>{t('faq.whatIsShoppingCircle.content070')}</em>
            {t('faq.whatIsShoppingCircle.content080')}
            {/*
            Maybe the person who shops determines <em>"Purchase or Not Purchase"</em> for every item on the list.
            */}
            <br/>
            {t('faq.whatIsShoppingCircle.content090')}
            {/*
            Maybe the Circle has a groundrule that says if there is at least 80% interest in getting something, they get it, and the people involved pay (and receive) a proportionate amount more for the item.
            */}
            <br/>
            {t('faq.whatIsShoppingCircle.content100')}
            {/*
            Or if the item was oversubscribed (e.g. 120% interest), then everyone receives a proportionate amount less.
            */}
            <br/>
            {t('faq.whatIsShoppingCircle.content110')}
            {/*
            Or "first come, first served", where priority for distribution goes according to the order in which folks joined on the order.
            */}
            <br/>
            {t('faq.whatIsShoppingCircle.content120')}
            {/*
            It's up to the group (and its admin/leader) to develop ground rules for this and other policies.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatIsShoppingCircle.content060')
                  + t('faq.whatIsShoppingCircle.content070')
                  + t('faq.whatIsShoppingCircle.content080')
                  + ' // '
                  + t('faq.whatIsShoppingCircle.content090')
                  + ' // '
                  + t('faq.whatIsShoppingCircle.content100')
                  + ' // '
                  + t('faq.whatIsShoppingCircle.content110')
                  + ' // '
                  + t('faq.whatIsShoppingCircle.content120')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.whatAndHowMuch.title010')}
          {t('faq.whatAndHowMuch.title020')}
          {/* How do shoppers indicate what (and how much) they want? */}
        </>,
        content: <>
          <p>
            {t('faq.whatAndHowMuch.content010')}
            {/*
            Every Shopping Circle builds its own list of items (\"Skus\") it might want to buy.
            There is a form for adding new Skus to the Circle's Maybe-List in which you register a product you would like your Circle to be able to purchase.
            Once you define an item as a Sku it is \"on the Maybe-List\" (available) to choose for any Trip.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatAndHowMuch.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatAndHowMuch.content020')}
            {/*
            Note: To build your Circle's Maybe-List, you can search for and copy Sku information that other Circles have shared.
            (As of Summer, 2024, very little content is shared, but this will grow with time.
            We look forward to removing this comment when that time comes!)
            If you find something useful you can easily pre-fill the Sku setup form with that other Sku's info.
            You can change any of this Sku info as needed.
            You are defining something new for YOUR Circle, so change the information you have copied however you like.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatAndHowMuch.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.whatAndHowMuch.content030')}
            {/*
            Once a Trip has been scheduled, you can add any of these items from the Maybe-List to the Trip's shopping list.
            When an item is "active" for a Trip, you and each member of your Circle can indicate how much you want of it.
            (You will see your share of the item's cost if/when it is purchased.)
            If there is enough combined interest for that item, your Circle can order/buy it.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whatAndHowMuch.content030')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.combinedInterest.title010')}
          {t('faq.combinedInterest.title020')}
          {/* What do you mean by "Combined Interest"? */}
        </>,
        content: <>
          <p>
            {t('faq.combinedInterest.content010')}
            {/*
            "Combined Interest" for an item represents the collection of orders for it from Shoppers in the Circle.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.combinedInterest.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.combinedInterest.content020')}
            {/*
            This concept is not relevant when a Shopper wants a complete item, e.g. a box of cereal or a carton of eggs. They don't plan to subdivide the item.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.combinedInterest.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.combinedInterest.content030')}
            {/*
            But, ShopWeAll supports friends pooling their requirements to purchase a larger size of something, with plans to then subdivide what they buy.
            This gets a better unit price for everyone than what the store offers for a smaller version of the same thing.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.combinedInterest.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.combinedInterest.content040')}
            {/*
            For example, you might only want 25% of the larger-volume item, but if another shopper in your circle wants 50% of the item, and a third person wants 25%, then together you have enough interest to justify purchasing the larger size and then splitting it up 25-25-50.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.combinedInterest.content040')
                })
              })
            }
          </p>
          <div>
            {t('faq.combinedInterest.content050')}
            {/* If we */}
            <br/>
            <ul>
              <li>{'* '+t('faq.combinedInterest.content060')}</li>
              {/*
              individually have the means to store larger amounts of things,
              */}
              <li>{'* '+t('faq.combinedInterest.content070')}</li>
              {/*
              and we consume them before they go bad,
              */}
              <li>{'* '+t('faq.combinedInterest.content080')}</li>
              {/*
              and we don't mind that our money is sitting on the shelf at home in product form rather than still available as cash to spend on something else,
              */}
            </ul>
            {t('faq.combinedInterest.content090')}
            {/*
            we can buy these larger-quantity items on our own.
            We might still want to coordinate purchases with others for the fun of shopping together(?) or if another person will do the shopping.
            (On a rotating basis?)
            This makes particular sense if the store is hard to reach (i.e. is some distance away, transportation issues, doesn't have night hours, etc.) and we wouldn't otherwise shop there.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.combinedInterest.content050')
                  + ' // '
                  + t('faq.combinedInterest.content060')
                  // + ' // '
                  + t('faq.combinedInterest.content070')
                  // + ' // '
                  + t('faq.combinedInterest.content080')
                  + ' // '
                  + t('faq.combinedInterest.content090')
                })
              })
            }
          </div>
          <br/>
          <p>
            {t('faq.combinedInterest.content100')}
            {/*
            You will probably continue to purchase most items individually.
            For working together to stretch your shopping dollar (or save on time or travel costs), ShopWeAll may be a useful option.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.combinedInterest.content100')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.notFullInterest.title010')}
          {t('faq.notFullInterest.title020')}
          {/* What if there is not 100% interest in getting something? */}
        </>,
        content: <>
          <p>
            {t('faq.notFullInterest.content010')}
            {/*
            Your Circle will develop policies for how to handle this.
            */}
            <br/>
            {t('faq.notFullInterest.content020')}
            {/*
            Maybe someone calls around to check for last minute interest.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.notFullInterest.content010')
                  + ' // '
                  + t('faq.notFullInterest.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.notFullInterest.content030')}
            {/*
            If there are 30 rolls of toilet paper in a package, and Circle members have only "ordered" 24 rolls, what do you do?
            You might have a rule like "80%" which says 80% is enough interest to trigger a purchase without consultation, and people who wanted the item will pay proportionately more to reach 100% (and get back a little more.)
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.notFullInterest.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.notFullInterest.content040')}
            {/*
            A similar problem exists if your Circle wants 35 rolls: Since the package only has 30, who isn't going to get five rolls?
            */}
            <br/>
            {t('faq.notFullInterest.content050')}
            {/*
            Again, maybe someone cuts back voluntarily, or you cut back proportionally, or maybe you look at the timestamps of the orders and whoever ordered last misses out.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.notFullInterest.content040')
                  + ' // '
                  + t('faq.notFullInterest.content050')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.whoShops.title010')}
          {t('faq.whoShops.title020')}
          {/* Who does the shopping? Is there delivery? */}
        </>,
        content: <>
          <p>
            {t('faq.whoShops.content010')}
            {/* That's up to your Circle. */}
          </p>
          <p>
            {t('faq.whoShops.content020')}
            {/* Maybe you take turns shopping. */}
            <br/>
            {t('faq.whoShops.content030')}
            {/*
            Maybe you shop together, or a pair of you does it.
            */}
            <br/>
            {t('faq.whoShops.content040')}
            {/*
            Maybe only one person has a special store membership card and they go on every trip.
            */}
            <br/>
            {t('faq.whoShops.content050')}
            {/*
            Maybe the person who shops collects a fee or gas money from others as a courtesy for the service.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoShops.content020')
                  + ' // '
                  + t('faq.whoShops.content030')
                  + ' // '
                  + t('faq.whoShops.content040')
                  + ' // '
                  + t('faq.whoShops.content050')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoShops.content060')}
            {/*
            Maybe you submit a large delivery order for a store or service and then Circle members pick up and pay for their items at the place/home where it was delivered.
            The delivery fee could be another item the group "purchases" and divides.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoShops.content060')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoShops.content070')}
            {/*
            Note that if you order online or take delivery of various goods, the price of the online items might be higher than they are in the store as a way to pay for delivery.
            If you sometimes get an item at the store and other times take delivery, it may help to set up duplicate Skus which differ in their price and the store you get them from, e.g. Acme and Acme Online.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoShops.content070')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.howDistribute.title010')}
          {t('faq.howDistribute.title020')}
          {/* How does distribution work? */}
        </>,
        content: <>
          <p>
            {t('faq.howDistribute.content010')}
            {/*
            Distribution of purchased items is another matter for the Circle.
            */}
            <br/>
            {t('faq.howDistribute.content020')}
            {/*
            Maybe the person who shopped holds everything at home and the other shoppers visit to pick up their goods.
            */}
            <br/>
            {t('faq.howDistribute.content030')}
            {/*
            (Or maybe the shopper has the time/interest for making deliveries?)
            */}
            <br/>
            {t('faq.howDistribute.content040')}
            {/*
            If storage of perishable items is an issue, maybe others in the Circle pick up as soon as the order is available.
            */}
            <br/>
            {t('faq.howDistribute.content050')}
            {/*
            Maybe the Circle holds a "pick-up" party!
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.howDistribute.content010')
                  + ' // '
                  + t('faq.howDistribute.content020')
                  + ' // '
                  + t('faq.howDistribute.content030')
                  + ' // '
                  + t('faq.howDistribute.content040')
                  + ' // '
                  + t('faq.howDistribute.content050')
                })
              })
            }
          </p>
          <p>
            {t('faq.howDistribute.content060')}
            {/*
            Perhaps Shopping Circle members live near a small town, and the store is 40 miles down the road.
            In that case, maybe meeting someplace in town after a Trip will be the most convenient for everyone.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.howDistribute.content060')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.whoSchedules.title010')}
          {t('faq.whoSchedules.title020')}
          {/* Who schedules Trips? */}
        </>,
        content: <>
          <p>
            {t('faq.whoSchedules.content010')}
            {/*
            Someone with administrative powers for the Circle will pick a day and time for a Trip.
            */}
            <br/>
            {t('faq.whoSchedules.content020')}
            {/*
            There might be one store you will visit or there might be several.
            */}
            <br/>
            {t('faq.whoSchedules.content030')}
            {/*
            Specify that when you set up the trip.
            */}
            <br/>
            {t('faq.whoSchedules.content040')}
            {/*
            The possibilities are interesting: Maybe the store is a restaurant(s) and the items are dishes that some people want to split!
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoSchedules.content010')
                  + ' // '
                  + t('faq.whoSchedules.content020')
                  + ' // '
                  + t('faq.whoSchedules.content030')
                  + ' // '
                  + t('faq.whoSchedules.content040')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoSchedules.content050')}
            {/*
            Nothing says you HAVE to shop at the exact time you set up, but for reliability's sake it will likely help to shop close to the time you picked.
            */}
            <br/>
            {t('faq.whoSchedules.content060')}
            {/*
            Choosing a date puts a stake in the ground for planning and deciding what to get.
            */}
            <br/>
            {t('faq.whoSchedules.content070')}
            {/*
            You can schedule multiple trips, such as one every three weeks, or a trip in advance of a holiday.
            */}
            <br/>
            {t('faq.whoSchedules.content080')}
            {/*
            Scheduling a Trip sets up a new shopping list where Circle members can plan for a trip on that date.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoSchedules.content050')
                  + ' // '
                  + t('faq.whoSchedules.content060')
                  + ' // '
                  + t('faq.whoSchedules.content070')
                  + ' // '
                  + t('faq.whoSchedules.content080')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoSchedules.content090')}
            {/*
            If a trip won't happen for a while, maybe someone in the Circle would find it useful to dash out early for an item that has 100% interest.
            That is, the next Trip is set for Saturday, but Eddie needs paper towels right now.
            Susan and Joe can wait but don't mind if Eddie purchases now. 
            After the purchase, the early birds could all reduce their original orders for the trip or maybe they would leave the Trip details in place and reckon up as part of the main Trip.
            Payment, delivery, communications would have considerations, but maybe the flexibility is worth it.
            The group could deal with such possibilities as they gain experience with each other.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoSchedules.content090')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoSchedules.content100')}
            {/*
            For some Circles, maybe it works to just change a past Trip's date to some new date in the future.
            This basically clones all orders of the original trip into being the initial orders of the "new" trip.
            (This assumes those items will be in stock!)
            You might need to update pricing for some Skus or else create new price entries for Skus with new prices.
            (This will preserve the pricing history for a Sku.)
            */}
          <br/>
            {t('faq.whoSchedules.content110')}
            {/*
            Circle members would need to review the status of their orders, i.e. adjust quantities requested, but recycling the trip gives a head start for planning.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoSchedules.content100')
                  + ' // '
                  + t('faq.whoSchedules.content110')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoSchedules.content120')}
            {/*
            Note: Changing a Trip's date will make it look like no trip happened on the prior date.
            If history of that Trip is a concern, download a report for it before changing its date.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoSchedules.content120')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.dividePurchases.title010')}
          {t('faq.dividePurchases.title020')}
          {/* How are purchases divided? */}
        </>,
        content: <>
          <p>
            {t('faq.dividePurchases.content010')}
            {/*
            For something like a pack of batteries, where the sku is made up of individual items, it's easy to open the package and divide the contents as planned.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.dividePurchases.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.dividePurchases.content014')}
            {/*
            If, at the store you discover there are four extra batteries as a promotion, you could simply divide them evenly. Or, you could edit the Sku and change the number of items in the Sku, followed by updating who got the batteries. This will update what people owe.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.dividePurchases.content014')
                })
              })
            }
          </p>
          <p>
            {t('faq.dividePurchases.content020')}
            {/*
            It's a little harder to divide something granular (free-flowing.)
            That is, the Circle needs its own standards for cleanliness and precision.
            */}
            <br/>
            {t('faq.dividePurchases.content030')}
            {/*
            For a gallon of milk, someone would keep the original container after pouring out a quart (or whatever) into clean containers that the other shoppers provide.
            */}
            <br/>
            {t('faq.dividePurchases.content040')}
            {/*
            For a one pound box of Salad, maybe use a big spoon and fork to lift out the estimated correct percentage into a clean resealable bag or plastic container.
            */}
            <br/>
            {t('faq.dividePurchases.content050')}
            {t('faq.dividePurchases.content052')}
            {/*
            Hmm, maybe washing a dish or bowl for takeaway is inconvenient? So get creative!
            &#x1F4A5;
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.dividePurchases.content020')
                  + ' // '
                  + t('faq.dividePurchases.content030')
                  + ' // '
                  + t('faq.dividePurchases.content040')
                  + ' // '
                  + t('faq.dividePurchases.content050')
                })
              })
            }
          </p>
          <p>
            {t('faq.dividePurchases.content060')}
            {/*
            It will be up to the Shopping Circle to determine the fairest and cleanest way to divide a granular purchase.
            */}
            <br/>
            {t('faq.dividePurchases.content070')}
            {/*
            A request for 33% of a bag of sugar should lead to approximately one-third of the bag going to that person.
            */}
            <br/>
            {t('faq.dividePurchases.content080')}
            {/*
            Over time the plusses and minuses that happen while dividing items should even out.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.dividePurchases.content060')
                  + ' // '
                  + t('faq.dividePurchases.content070')
                  + ' // '
                  + t('faq.dividePurchases.content080')
                })
              })
            }
          </p>
          <p>
            {t('faq.dividePurchases.content090')}
            {/*
            With enough activity, maybe it will be worthwhile to purchase a scale.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.dividePurchases.content090')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.handleMoney.title010')}
          {t('faq.handleMoney.title020')}
          {/* How does the Shopping Circle handle money? */}
        </>,
        content: <>
          <p>
            {t('faq.handleMoney.content010')}
            {/*
            The Shoppers in your Circle must each come up with the funds for purchasing the items they have "ordered."
            */}
            <br/>
            {t('faq.handleMoney.content020')}
            {/*
            The exact manner of exchanging funds is a matter for the Circle to decide.
            */}
            <br/>
            {t('faq.handleMoney.content030')}
            {/*
            The level of trust within the circle will guide the policy that members wish to follow.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.handleMoney.content010')
                  + ' // '
                  + t('faq.handleMoney.content020')
                  + ' // '
                  + t('faq.handleMoney.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.handleMoney.content040')}
            {/*
            Does someone go shopping and pay the bill, and then collect each person's portion of the cost?
            */}
            <br/>
            {t('faq.handleMoney.content050')}
            {/*
            Maybe they collect money in advance of the trip to be sure the cost is covered.
            (Emergencies happen, and suddenly someone doesn't have the funds they said they would.
            What are you going to do?)
            */}
            <br/>
            {t('faq.handleMoney.content060')}
            {/*
            Maybe even collect a little extra in case a price is higher than expected.
            */}
            <br/>
            {t('faq.handleMoney.content070')}
            {/*
            Maybe a different group member holds the money that people provide for the trip.
            This member would provide it to the person who shopped once they bring back the goods and they match what was expected.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.handleMoney.content040')
                  + ' // '
                  + t('faq.handleMoney.content050')
                  + ' // '
                  + t('faq.handleMoney.content060')
                  + ' // '
                  + t('faq.handleMoney.content070')
                })
              })
            }
          </p>
          <p>
            {t('faq.handleMoney.content080')}
            {/*
            At no point does the ShopWeAll system take in or dispense money to pay for Shopping Circle activity.
            */}
            <br/>
            {t('faq.handleMoney.content090')}
            {/*
            ShopWeAll strives to provide clear and accurate listings of who ordered what, for how much, and what the amount due should be.
            */}
            <br/>
            {t('faq.handleMoney.content100')}
            {/*
            If the Circle purchases different quantities of items than are planned for a Trip, or does not input the actual price that's in effect on the date of a Trip, or makes similar such mistakes, then the amounts ShopWeAll calculates and shows will be wrong.
            */}
            <br/>
            {t('faq.handleMoney.content110')}
            {/*
            There might even be a problem (bug) with our software!
            */}
            <br/>
            {t('faq.handleMoney.content120')}
            {/*
            Please notify us promptly if you notice such a problem:
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.handleMoney.content080')
                  + ' // '
                  + t('faq.handleMoney.content090')
                  + ' // '
                  + t('faq.handleMoney.content100')
                  + ' // '
                  + t('faq.handleMoney.content110')
                  + ' // '
                  + t('faq.handleMoney.content120')
                })
              })
            }
          </p>
          <div className="container-left">
            {t('faq.handleMoney.content130')}&nbsp;
            {/* (Click */}
            <RxChatBubble/>
            {t('faq.handleMoney.content140')}
            {/*  )  */}

            {/*
            Programming note: everything in this div must fit on one line in the user's device, so keep content of the div short.
            */}
          </div>
          <br/>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.settlingUp.title010')}
          {t('faq.settlingUp.title020')}
          {/* What are some issues with settling up? */}
        </>,
        content: <>
          <p>
            {t('faq.settlingUp.content010')}
            {/*
            A primary issue will be determining what was actually purchased at what price so that you know how much each party owes.
            */}
            <br/>
            {t('faq.settlingUp.content020')}
            {/*
            And having a way to manage all the items when they first come back from the store.
            */}
            <br/>
            {t('faq.settlingUp.content030')}
            {/*
            And what if something needs refrigeration?
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.settlingUp.content010')
                  + ' // '
                  + t('faq.settlingUp.content020')
                  + ' // '
                  + t('faq.settlingUp.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.settlingUp.content040')}
            {/*
            Everyone should have had a general idea before the trip of the expense to expect, since "someone" should work through the shopping list before the trip to firm up what to get.
            */}
            <br/>
            {t('faq.settlingUp.content050')}
            {/*
            If the price is different at the store or quantities were limited, the final bill will be different from the plan.
            */}
            <br/>
            {t('faq.settlingUp.content060')}
            {/*
            The Circle should have agreements beforehand about how to handle such differences.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.settlingUp.content040')
                  + ' // '
                  + t('faq.settlingUp.content050')
                  + ' // '
                  + t('faq.settlingUp.content060')
                })
              })
            }
          </p>
          <p>
            {t('faq.settlingUp.content070')}
            {/*
            Maybe the best policy is "Don't buy it" if the item doesn't match the group's expectations.
            However, that might mean several people don't get something they were counting on when the difference was only slight.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.settlingUp.content070')
                })
              })
            }
          </p>
          <p>
            {t('faq.settlingUp.content080')}
            {/*
            Maybe you trust whoever is doing the shopping to "do the right thing" and you roll with whatever is required financially to do it.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.settlingUp.content080')
                })
              })
            }
          </p>
          <p>
            {t('faq.settlingUp.content090')}
            {/*
            After the Trip someone should reconcile the shopping list regarding what was actually purchased and for how much.
            (That is, update the shopping list for the Trip to reflect what was purchased.
            This would include adjusting the actual weight of a "by weight" item like a block of cheese.)
            Maybe there will also be adjustments regarding who got what percentage of each purchase.
            After making any adjustments, there will be a clear listing of how much each person owes.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.settlingUp.content090')
                })
              })
            }
          </p>
          <p>
            {t('faq.settlingUp.content100')}
            {/*
            It's probably a good idea to download a copy of the adjusted trip report to have a record of the Trip.
            (Every shopper in the Circle is able to download a copy.)
            You will be able to view the file in a spreadsheet program since it will come as a CSV file ("Comma Separated Variables".)
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.settlingUp.content100')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.whoCanSee.title010')}
          {t('faq.whoCanSee.title020')}
          {/* Who can see my activity? */}
        </>,
        content: <>
          <p>
            {t('faq.whoCanSee.content010')}
            {/*
            Your shopping activity is visible to others in your Shopping Circle.
            Shoppers outside of your Circle cannot see this data.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content020')}
            {/*
            Technically, the data sits on servers ShopWeAll contracts for, and we might examine it for better understanding of customer requirements or to perform maintenance.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content030')}
            {/*
            It's possible that in the future we would depersonalize and aggregate certain data in order to provide marketable insights to third parties.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content040')}
            {/*
            Currently, the Username you use to sign in with is not searchable.
            To add you to a Shopping Circle, the Admin must know (or guess) your Username.
            You would share this term with the Admin outside of the ShopWeAll system, i.e. through conversation, email, etc.
            The Admin can now create an Invitation which you can find via the Home tab.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content040')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content050')}
            {/*
            The value for your Shortname is generally what identifies your activity for other Shoppers in your Circle.
            By default, your Shortname is made from the initials of your first and last name.
            (Otherwise from the first seven characters of your Username.) 
            You can change your Shortname's value if you like.
            It's possible that other Shoppers who use ShopWeAll might choose to use the same term for a Shortname, but it's unlikely that other Shoppers in your Circle(s) would have the same.
            If that happens somehow, you can/should change it in your Shopper Profile so that people in your Circle(s) will know it's you.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content050')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content060')}
            {/*
            There are a couple of ways you can share information about your Skus with other Shopping Circles.
            When your information is searchable, others can add your Sku information to their Maybe-List as their own Sku.
            This won't share any of your purchase activity for the Sku.
            It will just be the basic information for setting up a Sku.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content060')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content070')}
            {/*
            The first way of sharing is to leave a Circle's default setting for 'Share basic Sku info' as 'Yes'.
            This authorizes ShopWeAll to copy the standard information and pricing of your Skus to create anonymous searchable Skus.
            Info in a Sku's 'Details' field won't be shared in this way since it might contain personally identifying information.
            The 'standard' sku information should not contain anything personal.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content070')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content080')}
            {/*
            When other Circles use this Sku information, they will be trusting that it is accurate.
            (Strive for accuracy to make it easy to use your info!)
            Whoever uses such shared Sku information is responsible for inspecting it and adjusting any settings prior to using it.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content080')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content090')}
            {/*
            A second way to share is to set the 'Share details' property for a Sku to 'Yes'.
            This allows the Sku to appear in search results when it matches a shopper's search criteria.
            By choosing to share, you are requesting to include your Sku's 'Details' info with the Sku's basic info and current price.
            This also shares your Circle's name and Zip Code.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content090')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoCanSee.content100')}
            {/*
            We follow procedures to ensure secure access to your information, but unfortunately bad actors roam the web and have broken into "secure" systems before.
            There is a chance, however slight, that a bad actor could breach our system. :-(
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoCanSee.content100')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.moreAboutSharing.title010')}
          {t('faq.moreAboutSharing.title020')}
          {/* Who can see my activity? */}
        </>,
        content: <>
          <p>
            {t('faq.moreAboutSharing.content010')}
            {/*
    When you share information about your skus, it helps the ShopWeAll community accelerate setting up their own skus. Maybe your sku descriptions provide additional useful or humorous information about the skus.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.moreAboutSharing.content020')}
            {/*
    Sharing the price information that goes with a sku has a powerful effect. It crowd-sources pricing info about which stores are selling what items at what cost. It's not a guarantee: Prices change, and the shared price is only accurate \"as of\" the date you specify. If you update your sku's price, that value will carry over with your sku.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.moreAboutSharing.content030')}
            {/*
    Sharing information about your skus is not required. In fact,some might pervert this power and share incorrect or objectionable information, so always be watchful about what you use. (Please send us feedback if you see a problem.)
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.moreAboutSharing.content040')}
            {/*
    If you plan to share, then including information like a barcode number or a store code (or both!) in the identifier can help searchers find what they want. How so?
            */}
            <br/>
            {t('faq.moreAboutSharing.content050')}
            {/*
    (Note: The rest of this discussion covers only this topic. You can skip it if you won't be sharing or searching for sku info.)
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content040')
                  + ' // '
                  + t('faq.moreAboutSharing.content050')
                })
              })
            }
          </p>
          <p>
            {t('faq.moreAboutSharing.content080')}
            {/*
    Well, if you want information that other circles have about a certain sku, you can search for it using terms like the name or brand. A generic search term might return a wide range of results, and that can be useful.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content080')
                })
              })
            }
          </p>
          <p>
            {t('faq.moreAboutSharing.content090')}
            {/*
    However, if you search with an ID value for the sku, like a barcode number or maybe a store code, and other circles include that value in their skus' identifiers, then you will see a tailored list of only what you are looking for.
            */}
            <br/>
            {t('faq.moreAboutSharing.content100')}
            {/*
    This could be especially useful if you want to see the price of the sku across various stores or build up price data for the item over time.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content090')
                  + ' // '
                  + t('faq.moreAboutSharing.content100')
                })
              })
            }
          </p>
          <p>
            {t('faq.moreAboutSharing.content110')}
            {/*
    Helpfully, the search works even if the target's identifier has several components. For example, if an identifier has some random characters, along with a bar code number, and also a code the store uses for the product, so something like \"YN89 3550283729 6523478\", then if all you knew was the store code and searched with \"6523478\", then you would find this circle's sku!
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content110')
                })
              })
            }
          </p>
          <p>
            {t('faq.moreAboutSharing.content120')}
            {/*
    Bottom line: Your sku identifier can incorporate multiple values, any of which might help with finding your sku. The specificity about IDs increases confidence about the match and focuses the set of search results.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.moreAboutSharing.content120')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.areReportsAvailable.title010')}
          {t('faq.areReportsAvailable.title020')}
          {/* Are Reports Available? */}
        </>,
        content: <>
          <p>
            {t('faq.areReportsAvailable.content010')}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.areReportsAvailable.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.areReportsAvailable.content020')}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.areReportsAvailable.content020')
                })
              })
            }
          </p>
          <p>
            {t('faq.areReportsAvailable.content030')}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.areReportsAvailable.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.areReportsAvailable.content040')}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.areReportsAvailable.content040')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.whoMakesMoney.title010')}
          {t('faq.whoMakesMoney.title020')}
          {/* Who makes money? */}
        </>,
        content: <>
          <p>
            {t('faq.whoMakesMoney.content010')}
            {t('faq.whoMakesMoney.content012')}
            {/*
            With luck, Shoppers see notable savings over time by shopping together to get volume discounts!
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoMakesMoney.content010')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoMakesMoney.content020')}
            {/*
            ShopWeAll is subscription based, whereby the person who starts the Circle pays a monthly or semi-annual fee. They can then invite other Shoppers to join the Circle (for free or some other basis.)
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoMakesMoney.content020')
                })
              })
            }
          </p>
          <div>
            {t('faq.whoMakesMoney.content030')}
            <br/>
            <ul>
              <li>{'* '+t('faq.whoMakesMoney.content040')}</li>
              <li>{'* '+t('faq.whoMakesMoney.content050')}</li>
              <li>{'* '+t('faq.whoMakesMoney.content060')}</li>
              <li>{'* '+t('faq.whoMakesMoney.content070')}</li>
              <li>{'* '+t('faq.whoMakesMoney.content080')}</li>
            </ul>
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoMakesMoney.content030')
                  + ' // '
                  + t('faq.whoMakesMoney.content040')
                  + ' // '
                  + t('faq.whoMakesMoney.content050')
                  + ' // '
                  + t('faq.whoMakesMoney.content060')
                  + ' // '
                  + t('faq.whoMakesMoney.content070')
                  + ' // '
                  + t('faq.whoMakesMoney.content080')
                })
              })
            }
          </div>
          <br/>
          <p>
            {t('faq.whoMakesMoney.content130')}
            {/*
            Within the Circle, it's up to the leader and/or its shoppers to decide how to operate.
            */}
            <br/>
            {t('faq.whoMakesMoney.content140')}
            {/*
            Maybe it's all voluntary, with no fees.
            */}
            <br/>
            {t('faq.whoMakesMoney.content150')}
            {/*
            Maybe people contribute some amount for transportation or time spent shopping, managing, storing.
            */}
            <br/>
            {t('faq.whoMakesMoney.content160')}
            {/*
            If the purchase happens by delivery, it's likely that everyone will share in any delivery fee.
            (The fee could be handled as a Sku, and everyone pays a percentage.)
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoMakesMoney.content130')
                  + ' // '
                  + t('faq.whoMakesMoney.content140')
                  + ' // '
                  + t('faq.whoMakesMoney.content150')
                  + ' // '
                  + t('faq.whoMakesMoney.content160')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoMakesMoney.content170')}
            {/*
            If the Circle shops at a store that provides a rebate of some sort, e.g. 2% on purchases up to a ceiling amount, maybe the person who shops pockets the credit.
            */}
            <br/>
            {t('faq.whoMakesMoney.content180')}
            {/*
            Maybe there should be consideration for paying with a credit card that provides "Cash Back" for purchases.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoMakesMoney.content170')
                  + ' // '
                  + t('faq.whoMakesMoney.content180')
                })
              })
            }
          </p>
          <p>
            {t('faq.whoMakesMoney.content190')}
            {/*
            Maybe membership fees for a store don't factor in at all if shoppers maintain their own memberships and continue buying items that are solely personal.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.whoMakesMoney.content190')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.manageYourAccount.title010')}
          {t('faq.manageYourAccount.title020')}
          {/* How to delete your account? Join or leave or invite to a Circle? */}
        </>,
        content: <>
          <p>
            {t('faq.manageYourAccount.content010')}
            {t('home.editProfileButton.label')}
            {t('faq.manageYourAccount.content020')}
            {t('shopper.deleteAccountButton.label')}
            {t('faq.manageYourAccount.content030')}
            {/* 
            You may delete your account if you no longer wish to access ShopWeAll. Click on the 'Home' tab and look for the button labeled "Your Profile: View, Change or Delete." When you click this button, the next screen will have a button labeled "Delete My Account." Clicking this button will remove your order activity from all Trips, remove you from any Circles you have joined, and delete any outstanding Invitations you have. It will then delete your account. You or another person could set up a new account using the same identifier you had but it will not have access to your previous history.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.manageYourAccount.content010')
                  + ' // '
                  + t('home.editProfileButton.label')
                  + ' // '
                  + t('faq.manageYourAccount.content020')
                  + ' // '
                  + t('shopper.deleteAccountButton.label')
                  + ' // '
                  + t('faq.manageYourAccount.content030')
                })
              })
            }
          </p>
          <p>
            {t('faq.manageYourAccount.content040')}
            {/* 
            To join a Circle you must receive an Invitation from an Admin for that Circle. Circles come to life when a Shopper purchases a subscription, and that person has ultimate responsibility for all policies and decisions of the Circle. This includes controlling who can join the Circle. Presumably you know an Admin for the Circle or someone else who knows. Ask them for an invitation, tell them your account name, and then they can send you an invitation.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.manageYourAccount.content040')
                })
              })
            }
          </p>
          <p>
            {t('faq.manageYourAccount.content050')}
            {t('circleShopper.leaveCircle.toggleButtonPermitsLabel')}
            {t('faq.manageYourAccount.content060')}
            {t('circleShopper.leaveCircle.columnLabel')}
            {t('faq.manageYourAccount.content070')}
            {/* 
            To leave a Circle, click the 'Circles' tab. When you see the name of the Circle you wish to leave, click it and you will see a "Leave the Circle" button. Click it and a "Leave Circle?" column will appear with a button for leaving the Circle. (If you are the Leader of the Circle, you will need to either find a new Leader for the Circle or else delete the Circle itself.) 
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.manageYourAccount.content050')
                  + ' // '
                  + t('circleShopper.leaveCircle.toggleButtonPermitsLabel')
                  + ' // '
                  + t('faq.manageYourAccount.content060')
                  + ' // '
                  + t('circleShopper.leaveCircle.columnLabel')
                  + ' // '
                  + t('faq.manageYourAccount.content070')
                })
              })
            }
          </p>
          <p>
            {t('faq.manageYourAccount.content080')}
            {/* 
            You can only invite someone to join a Circle if you are an Admin of the Circle. Not an Admin? Ask the Circle's Leader to make you an Admin.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.manageYourAccount.content080')
                })
              })
            }
          </p>
          <p>
            {t('faq.manageYourAccount.content090')}
            {t('circleShopper.inviteShopperButton.label')}
            {t('faq.manageYourAccount.content100')}
            {/* 
            As an Admin, you can invite a Shopper to join your Circle if you know their account name. The Shopper will have to tell you this. You won't be able to pick the name from a list. Click on the main "Circles" tab and you will see the "Invite a Shopper to the Circle (requires admin)" button. Click this and enter the Shopper's account name to invite them to join. When they accept the invitation they will join your Circle and have access to its resources.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.manageYourAccount.content090')
                  + t('circleShopper.inviteShopperButton.label')
                  + ' // '
                  + t('faq.manageYourAccount.content100')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
      {
        title: <>
          {t('faq.feedbackAndQs.title010')}
          {t('faq.feedbackAndQs.title020')}
          {/* How to ask questions, get help, provide feedback? */}
        </>,
        content: <>
        <div>
          {t('faq.feedbackAndQs.content015')}
          {/*
            We want to hear your questions and comments! Please click the "speech bubble"
          */}
          <RxChatBubble/>
          {t('faq.feedbackAndQs.content040')}
          {/*
            at the top right of your screen and a form will open that lets you reach us. There is even a link there to Loom, Inc.'s website which makes it easy to record a video message/demo for your idea/concern. You would then paste a link to your message into the form.
          */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.feedbackAndQs.content015')
                  + t('faq.feedbackAndQs.content040')
                })
              })
            }
          </div>
          <br/>
          <p>
            {t('faq.feedbackAndQs.content050')}
            {/*
            It helps if you can include a "topic" line to provide a description of your message. We will respond to your message using the email address you provided upon signup.
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.feedbackAndQs.content050')
                })
              })
            }
          </p>
          <p>
            {t('faq.feedbackAndQs.content060')}
            {/*
            For documentation, there are tooltips and videos available throughout the site that provide context-sensitive information.
            (Additional help videos are on the way!)
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.feedbackAndQs.content060')
                })
              })
            }
          </p>
          <p>
            {t('faq.feedbackAndQs.content070')}
            {/*
            On mobile devices, it might be hard to get the tooltips to display.
            In that case, you can enable a setting in your profile that unhides the "help" icons.
            Clicking the icon will show the tooltip message in the webpage itself instead of as a tooltip.
            To stop showing these icons just change the setting back to "No".
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.feedbackAndQs.content070')
                })
              })
            }
          </p>
          <p>
            {t('faq.feedbackAndQs.content080')}
            {/*
            We look forward to hearing from you!
            */}
            {
              headingPlusPlayAudio({
                heading: ' ',
                origText: getAudioText({originalText: 
                    t('faq.feedbackAndQs.content080')
                })
              })
            }
          </p>
          &nbsp;
          <br/>
        </>
      },
    ],
  };

  return {
    SwaOverviewContent
  }
}