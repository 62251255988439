//import cloneDeep from 'lodash/cloneDeep';
// import _ from 'lodash';

import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import HeadingBuilder from '../components/HeadingBuilder';
import { BackButton } from '../components/BackButton';
import { Prices } from "./Prices";
import { SkuEditForm } from "../components/SkuEditForm";
import * as Constants from '../constants';
import { useLookups, usePhrasing } from "../hooks/useSkuUtils";
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import Table from 'react-bootstrap/Table';
import styles from '../css/trTdFirstChild.module.css';
import { useTranslation } from "react-i18next";

const Sku = () => {
  const { signedIn, shopper, skus, circleShoppers } = useContext(ShopperContext);

  console.log('Sku.js skus-orig: ', skus)
  const {id} = useParams();
  console.log('Sku.js id: ', id);
  
  const [searchParams] = useSearchParams();
  const showEdit = searchParams.get('showEdit');
  console.log('Sku.js showEdit', '|', showEdit, '|');

  const [formFlag, setFormFlag] = useState(showEdit ? true : false);

  const { lookup_category } = useLookups();
  const { tippySkuIdentifierIsWeightyString, noDotZero } = usePhrasing();
              
  const { tableShow, tableData } = useStyles();
  const { is_shopper_a_circle_admin } = useUtils();
  const { t } = useTranslation();

  // This logic from Trip.js (see comments there.)
  const navigate = useNavigate();
  if (skus.length === 0) {
    navigate('/skus/')
    // This is progress. At least something comprehensible happens upon pressing
    // a browser reload icon.
  }

 if (signedIn) {
  // load "sku" with the sku that matches the id param from url
  // Used cloning before (?) for circles to make clean (?) copy of circle.
  const sku = skus.find((sku) => {
    console.log('find sku: ', sku)
    console.log('find sku.id: ', sku.id)
    console.log('find sku boolean: ', sku.id == id)
    return sku.id == id  // TODO: get warning for '==', but '===' fails - quiesce?
  }) || {} ;

  console.log('find sku2: ', sku)
  console.log('find sku2 tax_type: ', sku.tax_type)

  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: sku.circle_id, shopper_id: shopper.id
  });

  const isDiscrete =  sku.type == "DiscreteItem" ? true : false;
  const isGranular =  sku.type == "GranularItem" ? true : false;
  const isWeighty  =  sku.type == "WeightyItem" ? true : false;

  const tippyTypeContent = t('sku.type.tippyContent') + " "
    + (isDiscrete ? t('sku.type.tippyContentDiscrete') : '')
    + (isGranular ? t('sku.type.tippyContentGranular') : '')
    + (isWeighty  ? t('sku.type.tippyContentWeighty')  : '');

  const tippySkuIdentifier =
      t('sku.identifier.tippyContent')
    + tippySkuIdentifierIsWeightyString({isWeighty})

  console.log("Sku category", sku.category);
  console.log("Sku lookup_category", lookup_category({category: sku.category || 0}));

  // present that sku's order_info; circle admin sees button allowing _______
  return (
    <article>
      <h3>
        {/* Sku Info: */}
        {t('sku.formPart.skuDetailHeadlineLabel')}
      </h3>
      
      <Table responsive striped bordered hover size="sm" style={tableShow}>
        <thead>
        </thead>
        <tbody className={styles.trTdFC} style={tableData} >
          <tr>
            <td>{t('sku.label.label')}</td>{/* Name/Label */}
            <td>
              {sku.label}
            </td>
          </tr>
          <tr>
            <td>{t('invitation.circle.label')}</td>{/* Circle */}
            <td>
              {sku.circle.identifier} - {sku.circle.postal_code}
            </td>
          </tr>
          <tr>
            {/* <td>{t('sku.store_label.label')}</td> */}
            {/* Store */}
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.store_label.label')}
                // Store
                tippyAlertText = {t('sku.store_label.tippyContent')}
                tippyText      = {t('sku.store_label.tippyContent')}
                tippyInfoText  = {t('sku.store_label.tippyContent2')}
              />
              {/* This item will be purchased at which store(s)? You might include... */}
            </td>
            <td>
              {sku.store_label}
            </td>
          </tr>
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.tax_type.label')}
                // Tax Status
                tippyAlertText = {t('sku.tax_type.tippyContent')}
                tippyText      = {t('sku.tax_type.tippyContent')}
              />
              {/* What type of Sales Tax Rate will apply for this item? */}
            </td>
            <td>
              {
                (sku.tax_type == 0  ||  sku.tax_type == null)
                  ? 'No Tax'
                  : (sku.tax_type == 1  ? 'Regular' : 'Food')
                  // TODO: is rolling over to 'Food', thinking we've ruled out 0 and 1 ok?
              }
            </td>
          </tr>
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.category.label')}
                // Category
                tippyAlertText = {t('sku.category.tippyContent')}
                tippyText      = {t('sku.category.tippyContent')}
                // What category is the item? (Helpful for sorting.)
              />
            </td>
            <td>
                { lookup_category({category: sku.category || 0}) }
            </td>
          </tr>
          <tr>
            <td>{t('sku.brand.label')}</td>{/* Brand */}
            <td>
              {sku.brand}
            </td>
          </tr>
        { isDiscrete &&
          <tr>
              <td>{t('sku.unit_count.label')}</td>{/* Num Items in Sku */}
              <td>
                {noDotZero({num: sku.unit_count})}
              </td>
          </tr>
        }
        { (isDiscrete || isGranular) &&
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.contents.label')}
                // Contains
                tippyAlertText = {t('sku.contents.tippyContent')}
                tippyText      = {t('sku.contents.tippyContent')}
              />
              {/* What do you get with this item?  */}
            </td>
            <td>
              {noDotZero({num: sku.quantity == 0 ? '' : sku.quantity })} {sku.units}
            </td>
          </tr>
        }
        { isWeighty &&
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.typical_weight.label')}
                // Typical weight
                tippyAlertText = {t('sku.typical_weight.tippyContent')}
                tippyText      = {t('sku.typical_weight.tippyContent')}
              />
              {/* Since the item is sold by weight (e.g. Chicken Thighs are sold by the pound), how much does a typical package at this store weigh? */}
            </td>
            <td>
              {sku.typical_weight} {sku.units}
            </td>
          </tr>
        }
        { Constants.SHOW_SHAREABLE &&
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.shareable.label')}
                // Share details
                tippyAlertText = {t('sku.shareable.tippyContent')}
                tippyText      = {t('sku.shareable.tippyContent')}
              />
              {/* Would you like to share this item's information with other Shopping Circles? */}
            </td>
            <td>
              { sku.shareable ? 'Yes' : 'No'}
            </td>
          </tr>
        }
        { if_shopper_is_circle_admin &&
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.is_hidden.label')}
                // Hidden
                tippyAlertText = {t('sku.is_hidden.tippyContent')}
                tippyText      = {t('sku.is_hidden.tippyContent')}
              />
              {/* Do you want to reduce visibility of this Sku? e.g. not show it as one of the Circle's skus when it's time to plan a trip? etc. */}
            </td>
            <td>
              { sku.is_hidden ? 'Yes' : 'No'}
            </td>
          </tr>
        }
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.type.label')}
                // Type
                tippyAlertText = { tippyTypeContent }
                tippyText      = { tippyTypeContent }
              />
              {/* How did you classify this type of item? 'Weighty' is for... */}
            </td>
            <td>
                { sku.type.replace(/Item$/, '') }
            </td>
          </tr>
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.identifier.label')}
                // ID
                tippyAlertText = {tippySkuIdentifier}
                tippyText      = {tippySkuIdentifier}
                tippyInfoText  = {t('sku.identifier.tippyContentInfo')}
              />
              {/* Short identifier for the item such as a store code, barcode, or a term you make up. If use barcode, don't include the little digits at bottom corners. */}
            </td>
            <td>
              {sku.identifier}
            </td>
          </tr>
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('sku.detail.label')}
                // Details
                tippyAlertText = {t('sku.detail.tippyContentSearch')}
                tippyText      = {t('sku.detail.tippyContentSearch')}
              />
              {/* Additional details/info about the item */}
            </td>
            <td>
              {sku.detail}
            </td>
          </tr>
        </tbody>
      </Table>
      { formFlag
        ? <SkuEditForm
            circle_id = {sku.circle.id}
            skuParam = {sku}
          />
        : <span>
            <HeadingBuilder
              headingText    = {t('sku.editSkuButton.label')}
              // Edit Sku
              buttonAction   = {() => {setFormFlag(true)}}
  
              tippyAlertText = {t('sku.editSkuButton.tippyContent')}
              tippyText      = {t('sku.editSkuButton.tippyContent')}
            />
            <br />
            <BackButton />
          </span>
      }
      <hr />
      <Prices
          circle_id = {sku.circle.id}
          sku = {sku}
      />
    </article>
  )

 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}
export default Sku