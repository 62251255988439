import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import { ErrorList } from './ErrorList';
import { useForm } from "../hooks/useForm";
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { BackButton } from './BackButton';
import { useStyles } from "../hooks/useStyles";
import { usePhrasing } from "../hooks/useSkuUtils";
import HeadingBuilder from './HeadingBuilder';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
//import styled from 'styled-components';
import { useUtils } from "../hooks/useUtils";
import styles from '../css/trTdFirstChild.module.css';

export const SkuEditForm = ( {skuParam} ) => {
    const { signedIn, shopper, skus, circleShoppers, editSku, fetchOrderItems } = useContext(ShopperContext);

    const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
    const { setJustGotClearToFalse } = useContext(ShopperContext);
    const { is_shopper_a_circle_admin } = useUtils();
    const { tippySkuIdentifierIsWeightyString,
            typicalWeightLabel,
            typicalWeightTippyText,
          } = usePhrasing();

    const { tableShow, tableData, visibilityCollapse } = useStyles();

    // TODO: I'm assuming we'll only have an 'id' value upon fresh invocation
    // via link. There won't be some other 'id' value hanging around, right?
    const {id} = useParams();

    // If the form was invoked by Link, load the sku via state lookup of id.
    // Else assign the skuParam value passed to this component as a prop.
    const sku =
      id == null
        ? skuParam
        : skus.find(s => s.id == id); 
    console.log('SkuEditForm.js sku: ', sku)
    console.log('SkuEditForm.js skuType: ', sku.type)

    const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
      cShoppers: circleShoppers, circle_id: sku.circle_id, shopper_id: shopper.id
    });

    const isDiscrete =  sku.type == "DiscreteItem" ? true : false;
    const isGranular =  sku.type == "GranularItem" ? true : false;
    const isWeighty  =  sku.type == "WeightyItem" ? true : false;

    const { t } = useTranslation();
    const initialState = {...sku,
      // looks like we need to initialize typical_weight while old/quirky data still in use
      typical_weight: sku.typical_weight || '',
      // and also initialize tax_type if null is possible to avoid Warning of:
      // `value` prop on `select` should not be null. Consider using an empty
      // string to clear the component or `undefined` for uncontrolled components.
      tax_type: sku.tax_type == null ? '' : sku.tax_type,
      shareable: sku.shareable ? 1 : 0,
      is_hidden: sku.is_hidden ? 1 : 0,
      category: sku.category == null ? '' : sku.category
    };

    // const initialState = {
    //   circle_id: circle_id,
    //   shopper_id: '',
    //   currency: '$',
    //   price_date: '',
    //   discount_amount: '0',
    //   discount_start_date: '',
    //   discount_end_date: '',
    //   store_label: '',
    //   identifier: '',
    //   brand: '',
    //   label: '',
    //   detail: '',
    //   category: '',
    //   unit_count: (isGranular || isWeighty) ? 1 : '',
    //   typical_weight: '',
    //   price: '',
    //   tax_type: '',
    //   quantity: isWeighty ? 1 : '',
    //   units: 'oz',
    //   unit_price: '',
    //   type: skuType,
    //   shareable: 0,
    //   is_hidden: 0,
    // };
    console.log("sef: sku", sku);
    console.log("sef: initialState", initialState);

    const { formData, handleChange, handleChangeWithWhitespace, reset, editFormValues } = useForm(initialState);

    if (signedIn) {
      const handleSubmit = (e) => {
          e.preventDefault();

          // clear out any error messages, success statuses
          dismissErrorsList();
          setErrorsDoExistToFalse();
          setJustGotClearToFalse();

          console.log('formData for editSku: ', formData);

          // Note: We provide access to skuEdit links on the ShoppingList view.
          // If shopper edits a sku, we want to see those changes on the list
          // immediately. But sku data is accessed via orderItems and their
          // belongs_to relationship with skus. As such, we need to refresh
          // orderItems in state after updating the sku to provide access to
          // the new sku info, so do 'await editSku' to force this sequencing.
          (async function () {
            await editSku(formData);
            console.log('sef es complete')
            fetchOrderItems();
          })();

          // If there is an error we want to preserve formData and show the message.
      }

      const tippyUnitCount =
        t('sku.unit_count.tippyContent') + t('sku.unit_count.tippyContentEdit');

      const tippySkuIdentifier =
          t('sku.identifier.tippyContent')
        + tippySkuIdentifierIsWeightyString({isWeighty})

      return (
        <article>
          <hr/>
          <h3>
            {/* Edit Sku: */}
            {t('sku.formPart.editSkuHeadlineLabel')}
          </h3>
          <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.store_label.label')}
          // Store
          labelHtmlFor   = 'store_label_skef'
          tippyAlertText = {t('sku.store_label.tippyContent')}
          tippyText      = {t('sku.store_label.tippyContent')}
          tippyInfoText  = {t('sku.store_label.tippyContent2')}
        />
        {/* This item will be purchased at which store(s)? You might include... */}
      </td>
      <td>
        <input
          key="10"
          type="text"
          id="store_label_skef"
          name="store_label"
          onChange={handleChange}
          value={formData.store_label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.brand.label')}
          // Brand
          labelHtmlFor   = 'brand_skef'
          tippyAlertText = {t('sku.brand.tippyContent')}
          tippyText      = {t('sku.brand.tippyContent')}
          // e.g. Green Giant, General Mills, Heinz.
        />
      </td>
      <td>
        <input
          key="30"
          type="text"
          id="brand_skef"
          name="brand"
          onChange={handleChange}
          value={formData.brand}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
      <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.label.label')}
          // Name/Label
          labelHtmlFor   = 'label_skef'
          tippyAlertText = {t('sku.label.tippyContent')}
          tippyText      = {t('sku.label.tippyContent')}
          // Short, quick name of the item.
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          id="label_skef"
          name="label"
          onChange={handleChange}
          value={formData.label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.detail.label')}
          // Details
          labelHtmlFor   = 'detail_skef'
          tippyAlertText = {t('sku.detail.tippyContent')}
          tippyText      = {t('sku.detail.tippyContent')}
          // Any details you would like to add for the item.
        />
      </td>
      <td>
        <textarea
          key="50"
          id="detail_skef"
          name="detail"
          onChange={handleChange}
          value={formData.detail}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.category.label')}
          // Category
          labelHtmlFor   = 'category_skef'
          tippyAlertText = {t('sku.category.tippyContent')}
          tippyText      = {t('sku.category.tippyContent')}
          // What category is the item? (Helpful for sorting.)
        />
      </td>
      <td>
        <select
          key="55"
          type="text"
          id="category_skef"
          name="category"
          onChange={handleChange}
          value={formData.category}
        >
          {/* <option value="">Choose one:/option> */}
          <option value="">{t('sku.category.optionValue')}</option>
          {/* <option selected value="0">Other</option> // wish this worked... */}
          {/* <option value="0">Other</option> */}
          <option value="0">{t('sku.category.optionValue0')}</option>
          {/* <option value="1">Fruits</option> */}
          <option value="1">{t('sku.category.optionValue1')}</option>
          {/* <option value="2">Vegetables</option> */}
          <option value="2">{t('sku.category.optionValue2')}</option>
          {/* "optionValue3": "Dairy", */}
          <option value="3">{t('sku.category.optionValue3')}</option>
          {/* "optionValue4": "Bread, Bakery, Dessert", */}
          <option value="4">{t('sku.category.optionValue4')}</option>
          {/* "optionValue5": "Baking", */}
          <option value="5">{t('sku.category.optionValue5')}</option>
          {/* "optionValue6": "Meat, Fish", */}
          <option value="6">{t('sku.category.optionValue6')}</option>
          {/* "optionValue7": "Meat Alternatives", */}
          <option value="7">{t('sku.category.optionValue7')}</option>
          {/* "optionValue8": "Deli", */}
          <option value="8">{t('sku.category.optionValue8')}</option>
          {/* "optionValue9": "Cans, Jars", */}
          <option value="9">{t('sku.category.optionValue9')}</option>
          {/* "optionValue10": "Pasta, Rice, Cereal", */}
          <option value="10">{t('sku.category.optionValue10')}</option>
          {/* "optionValue11": "Sauces, Condiments", */}
          <option value="11">{t('sku.category.optionValue11')}</option>
          {/* "optionValue12": "Herbs, Spices", */}
          <option value="12">{t('sku.category.optionValue12')}</option>
          {/* "optionValue13": "Frozen Foods", */}
          <option value="13">{t('sku.category.optionValue13')}</option>
          {/* "optionValue14": "Snacks", */}
          <option value="14">{t('sku.category.optionValue14')}</option>
          {/* "optionValue15": "Drinks", */}
          <option value="15">{t('sku.category.optionValue15')}</option>
          {/* "optionValue16": "Household, Cleaning", */}
          <option value="16">{t('sku.category.optionValue16')}</option>
          {/* "optionValue17": "Personal Care, Beauty", */}
          <option value="17">{t('sku.category.optionValue17')}</option>
          {/* "optionValue18": "Health, Vitamins", */}
          <option value="18">{t('sku.category.optionValue18')}</option>
          {/* "optionValue19": "Baby products", */}
          <option value="19">{t('sku.category.optionValue19')}</option>
          {/* "optionValue20": "Pet Care", */}
          <option value="20">{t('sku.category.optionValue20')}</option>
          {/* "optionValue21": "Office Supplies", */}
          <option value="21">{t('sku.category.optionValue21')}</option>
          {/* "optionValue22": "Clothing", */}
          <option value="22">{t('sku.category.optionValue22')}</option>
          {/* "optionValue23": "Automotive", */}
          <option value="23">{t('sku.category.optionValue23')}</option>
          {/* "optionValue24": "Electronics", */}
          <option value="24">{t('sku.category.optionValue24')}</option>
          {/* "optionValue25": "Patio, Lawn, Garden" */}
          <option value="25">{t('sku.category.optionValue25')}</option>
        </select>
        &nbsp;*
      </td>
    </tr>

    {/* "tippyContent": "The item contains individual units. How many are there?" */}
    <tr
      // If isDiscrete, then don't hide this row. Else hide row.
      style={isDiscrete ? null : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.unit_count.label')}
          // Num Items in Sku
          labelHtmlFor   = 'unit_count_skef'
          tippyAlertText = {tippyUnitCount}
          tippyText      = {tippyUnitCount}
          tippyInfoText  = {t('sku.unit_count.tippyContent2')}
          // The item contains individual units. How many are there?
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="60"
          type="text"
          id="unit_count_skef"
          name="unit_count"
          onChange={handleChange}
          value={formData.unit_count}
          decimalScale={2}
          hidden={isDiscrete ? false : true}
        />
        &nbsp;*
      </td>
    </tr>
    <tr
      style={isWeighty ? null : visibilityCollapse }
    >
      <td>
        {/* TODO: would be nice to put this in bold if we arrived via Link, i.e. by someone
        clicking on the "EST 12.5 lb" link in the ShoppingList. The bolding would make
        it easier to see the key field to edit. Signal by appending option to link? */}
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {typicalWeightLabel()}
          // Typical weight (Only a number, no units)
          labelHtmlFor   = 'typical_weight_skef'
          tippyAlertText = {typicalWeightTippyText()}
          tippyText      = {typicalWeightTippyText()}
          // If the item is sold by weight (e.g. Chicken Thighs are sold by the pound), how much does a typical package weigh? If a package of Chicken Thighs is typically 10 pounds, enter '10' here.
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="70"
          type="text"
          id="typical_weight_skef"
          name="typical_weight"
          onChange={handleChange}
          value={formData.typical_weight}
          decimalScale={2}
          hidden={isWeighty ? false : true}
        />
        &nbsp;*
      </td>
    </tr>
    <tr
      style={isWeighty ? visibilityCollapse : null }
    >
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.quantity.label')}
          // Total Weight/Quantity
          labelHtmlFor   = 'quantity_skef'
          tippyAlertText = {t('sku.quantity.tippyContent')}
          tippyText      = {t('sku.quantity.tippyContent')}
          tippyInfoText  = {t('sku.quantity.dontSayUnits')}
          // What is the total weight/quantity of the product? For example, if the package contains six 10-oz cans of something, the value here would be 60. ** Enter '1' if the item is priced by weight (meat, fish, etc.)
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="80"
          type="text"
          id="quantity_skef"
          name="quantity"
          onChange={handleChange}
          value={formData.quantity}
          decimalScale={2}
          hidden={isWeighty ? true : false}
        />
        {
          isGranular ? <>&nbsp;*</> : null
        }
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.units.label')}
          // Units, e.g. oz, g, lb, ft2
          labelHtmlFor   = 'units_skef'
          tippyAlertText = {t('sku.units.tippyContent')}
          tippyText      = {t('sku.units.tippyContent')}
          // What is the unit of measure for the item's quantity/total weight? Is it measured in ounces (oz), pounds (lb), square feet (ft2)? Maybe you are buying a pack of 40 AA batteries, so you would put 'AA battery'.
        />
      </td>
      <td>
        <input
          key="90"
          type="text"
          id="units_skef"
          name="units"
          onChange={handleChange}
          value={formData.units}
        />
        {
          isWeighty && <span>&nbsp;*</span>
        }
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.identifier.label')}
          // ID
          labelHtmlFor   = 'identifier_skef'
          tippyAlertText = {tippySkuIdentifier}
          tippyText      = {tippySkuIdentifier}
          tippyInfoText  = {t('sku.identifier.tippyContentInfo')}
        />
      </td>
      <td>
        <input
          key="20"
          type="text"
          style={{ textTransform: "uppercase" }}
          id="identifier_skef"
          name="identifier"
          onChange={handleChangeWithWhitespace}
          value={formData.identifier}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.tax_type.label')}
          // Tax Status
          labelHtmlFor   = 'tax_type_skef'
          tippyAlertText = {t('sku.tax_type.tippyContent')}
          tippyText      = {t('sku.tax_type.tippyContent')}
          // What type of Sales Tax Rate will apply for this item?
        />
      </td>
      <td>
        <select
          key="155"
          type="text"
          id="tax_type_skef"
          name="tax_type"
          onChange={handleChange}
          value={formData.tax_type}
        >
          {/* <option value="">Choose one:</option> */}
          <option value="">{t('sku.tax_type.optionValue')}</option>
          {/* <option selected value="0">No Tax</option> // wish this worked... */}
          {/* <option value="0">No Tax</option> */}
          <option value="0">{t('sku.tax_type.optionValue0')}</option>
          {/* <option value="1">Regular</option> */}
          <option value="1">{t('sku.tax_type.optionValue1')}</option>
          {/* <option value="2">Food</option> */}
          <option value="2">{t('sku.tax_type.optionValue2')}</option>
        </select>
        &nbsp;*
      </td>
    </tr>
    <tr
      style={ Constants.SHOW_SHAREABLE ? null : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.shareable.label')}
          // Share details
          labelHtmlFor   = 'shareable_skef'
          tippyAlertText = {t('sku.shareable.tippyContent')}
          tippyText      = {t('sku.shareable.tippyContent')}
          // Yes/No: Would you like to share this item's information with other Shopping Circles?
        />
      </td>
      <td>
        <select
          key="160"
          type="text"
          id="shareable_skef"
          name="shareable"
          onChange={handleChange}
          value={formData.shareable}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('sku.shareable.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('sku.shareable.optionValue1')}</option>
        </select>
      </td>
    </tr>
    <tr
      style={ if_shopper_is_circle_admin ? null : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.is_hidden.label')}
          // Hidden
          labelHtmlFor   = 'is_hidden_skef'
          tippyAlertText = {t('sku.is_hidden.tippyContent')}
          tippyText      = {t('sku.is_hidden.tippyContent')}
          // Do you want to reduce visibility of this Sku? e.g. not show it as one of the Circle's skus when it's time to plan a trip? etc.
        />
      </td>
      <td>
        <select
          key="165"
          type="text"
          id="is_hidden_skef"
          name="is_hidden"
          onChange={handleChange}
          value={formData.is_hidden}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('sku.is_hidden.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('sku.is_hidden.optionValue1')}</option>
        </select>
      </td>
    </tr>
  </tbody>
</Table>
          {/* TODO: ?Will translating Submit be handled automatically by html?
              https://www.w3.org/International/wiki/Locale-based_forms */}
            <input key="170" type='submit' value='Submit' />
          </form>
          { errorsDoExist && <ErrorList /> }
          <BackButton />
          {/* <BackButton whatToDo={resetSkuTypeFormFlags}/> */}
        </article>
      )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default SkuEditForm;