import React, { useContext, useEffect, useRef } from 'react';
import { ShopperContext } from '../context/shopper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

//export const BackButton = (whatToDo, args) =>
export const BackButton = ({whatToDo, lockRight=true}) => {
  const { justGotClear, setJustGotClearToFalse } = useContext(ShopperContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const callback = whatToDo || navigate;
  // console.log("bb callback", callback);
  // const cb_args = args || [-1];
  // console.log("bb cb_args", cb_args);

  // Clear out any lingering 'Success! - Go back' messaging that wasn't
  // cleared before.
  // If it actually converts from true to false, there will be a flicker on
  // the screen since it will take a moment for 'useEffect' to take effect.
  // TODO: Maybe there's a way to prevent the flicker?
  useEffect(() => {
    setJustGotClearToFalse();
  }, [setJustGotClearToFalse]);

  const successRef = useRef(null);
  useEffect(() => {
    if (justGotClear) {
      successRef.current.scrollIntoView();
    }
  }, [justGotClear, successRef]);

  return (
    <button
      ref={successRef}
      className = {`
        ${lockRight    && 'backButton'}
        ${justGotClear && 'btn-just-got-clear'}
      `}

      onClick={() => {
        setJustGotClearToFalse();

        if (whatToDo) {
          console.log("bb callback", whatToDo);
          whatToDo();
        }
        else {
          console.log("bb callback", 'did navigate(-1)');
          navigate(-1);
        }
      }}
    >
      <span>{justGotClear && t('global.success') + ' - '} {t('global.goBack')}</span>
      {/* "Go back" -or- "Success! - Go back" */}

    </button>
  )
}

export default BackButton