import React from 'react';

import { BiSearchAlt2 } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import { IoBuildOutline } from "react-icons/io5";
import { TiArrowForwardOutline } from "react-icons/ti";
import { AiOutlineSound } from "react-icons/ai";
import { BsCameraReels } from "react-icons/bs";

const renderSwitch = (param) => {
  switch(param) {
    case 'arrowRight':
      return < TiArrowForwardOutline />;
    case 'hide':
      return < BiHide />;
    case 'wrench':
      return < IoBuildOutline />;
    case 'magnify':
      return < BiSearchAlt2 />;
    case 'audioPlayback':
      return < AiOutlineSound />;
    case 'videoPlayback':
      return < BsCameraReels />;
    // case 'videoPlayback1.5em':
    //   return < BsCameraReels size={'1.5em'} />;
    default:
      return param;
  }
}

export const GetIcon = ( {toGet} ) => {
  return renderSwitch(toGet);
}

export default GetIcon