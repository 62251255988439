import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import { useForm } from "../hooks/useForm";
import * as Constants from '../constants';
import { HeadingBuilder } from "./HeadingBuilder";
import { useTranslation } from "react-i18next";
import 'tippy.js/dist/tippy.css';

const Signin = () => {
  const [errorsList, setErrorsList] = useState([]);
  const { signin } = useContext(ShopperContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { reason, theToken } = useParams();

  console.log('signin reason param:', reason)

  // Easy to keep page appearance constant by not making separate components.
  // A 'reason' URL element/param controls what displays on Signin page.
  // 'Signin' button in 'Navbar' invokes '/signin/fresh'.
  // 'fresh' means fresh start at signing in, i.e. the normal condition
  // 'goto_pwreset' means to learn identifier and email for reset password
  // 'pwreset' means to get identifier and new password values to reset password

  const initialState = {
    identifier: '',
    password: '',
    password_confirmation: '',
    email: '',
    token: theToken ? theToken : '',
  };
  const { formData, handleChange, reset, editFormValues } = useForm(initialState);

  const handleForgotPasswordClick = event => {
    console.log('click handleResetPassword')
    setErrorsList([])
    reset()
    navigate('/signin/goto_pwreset')
  };

  useEffect(() => {
    setErrorsList([]);
  }, [reason, setErrorsList]);

  // if 'errorsList' gets new messages, scroll it into view
  const errorsListRef = useRef(null);
  useEffect(() => {
    if (errorsList  &&  errorsList.length > 0) {
      errorsListRef.current.scrollIntoView();
    }
  }, [errorsListRef, errorsList]);
          
  function mkHtmlListOfErrorMessagesSignin() {
    // invoke with a list of error strings, e.g.: mkHtmlListOfErrorMessagesSignin(...data.errors)
    // TODO: Logic is same as in 'shopper.js' - dry this?
    const args = [...arguments]
    return [...new Set(args)].map(err => <li className="stdError" key={err} >{err}</li>)
  }

  function mkHtmlListOfSuccessMessagesPwReset() {
    // invoke with a list of success_messages
    const args = [...arguments]
    return [...new Set(args)].map(sMsg => <li className="successMessage" key={sMsg} >{sMsg}</li>)
  }
            
  const signinHandleSubmit = (e) => {
    e.preventDefault();
    setErrorsList([]);
    const configObj = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData })
    }

    fetch('/api/signin', configObj)
    .then(res => res.json())
    .then(shopper => {
        //console.log("Signin.js fetch /api/signin", shopper)
        if (!shopper.errors) {
            // TODO: test me for errors handled via array
            signin(shopper)
            navigate('/')  // TODO: where should we go? How to go "Home"?

        } else {
            reset()
            const errorLis = mkHtmlListOfErrorMessagesSignin(...shopper.errors);
            setErrorsList(errorLis)
        }
    })
  }

  const forgotPasswordHandleSubmit = (e) => {
    resetPasswordHandleSubmitPostOrPatch(e, 'POST');
  }

  const resetPasswordHandleSubmit = (e) => {
    resetPasswordHandleSubmitPostOrPatch(e, 'PATCH');
  }

  const resetPasswordHandleSubmitPostOrPatch = (e, theMethod) => {
    e.preventDefault();
    setErrorsList([]);
    const configObj = {
      method: theMethod,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...formData })
    }
    // console.log('signin rphspop patch:', theMethod, configObj)

    fetch('/api/password_reset', configObj)
    .then(res => res.json())
    .then(outcome => {
      if (!outcome.errors) {
        const successMsgs = mkHtmlListOfSuccessMessagesPwReset(...outcome.success_messages);
        console.log('pw_reset success:', theMethod, successMsgs)
        setErrorsList(successMsgs)
      } else {
        reset()
        const errorLis = mkHtmlListOfErrorMessagesSignin(...outcome.errors);
        console.log('pw_reset fail:', theMethod, errorLis)
        setErrorsList(errorLis)
      }
    })
  }

  // contents of 'tObj' will be controlled by 'reason'
  const tObj =
    reason === 'pwreset'
  ? {
      mainHeading: t('signin.pwResetToken.mainHeading'),
      // CHANGE PASSWORD
      subHeading:  t('signin.pwResetToken.subHeading'),
      // Enter new password, twice
      submitHandler: resetPasswordHandleSubmit,
      submitIsDisabled: (
        formData.identifier.trim().length === 0
          ||
        formData.password.trim().length === 0
          ||
        formData.password_confirmation.trim().length === 0
      ),
    }
  : reason === 'goto_pwreset'
  ? {
      mainHeading:      t('signin.pwResetRequest.mainHeading'),
      // Reset Your Password
      subHeading:  <> { t('signin.pwResetRequest.subHeading1') }
                 <br/>{ t('signin.pwResetRequest.subHeading2') }</>,
      // Enter Your Username and Email
      // We'll email a link to let you reset your password.
      submitHandler: forgotPasswordHandleSubmit,
      submitIsDisabled: (
        formData.identifier.trim().length === 0
          ||
        formData.email.trim().length === 0
      ),
    }
  : // reason === 'fresh'
    {
      mainHeading: t('global.label.signIn'),
      // SIGN IN
      subHeading:  <>{ t('signup.tagline.line1') }
                <br/>{ t('signup.tagline.line2') }</>,
      // And tell EVERYBODY<br/>to join you in the savings!
      submitHandler: signinHandleSubmit,
      submitIsDisabled: (
        formData.identifier.trim().length === 0
          ||
        formData.password.trim().length === 0
      ),
    };
  // console.log('signin reason param:', reason)
  // console.log('signin tObj', tObj)
  // console.log('signin tObj.mainHeading', tObj.mainHeading)
  // console.log('signin tObj.subHeading', tObj.subHeading)

  return (
    <>
    <div className="container-fluid mt-5 ">
    <div className='signInBkgrnd' id="duotone_sign_in"></div>

    <div className="container mt-6 p-5">
      <h1 className="fs-1 white fw-light">{tObj.mainHeading}</h1>
        {/* SIGN IN */}
      <p className='fs-3'>{tObj.subHeading}
        {/* And tell EVERYBODY\n to join you in the savings! */}
      </p>
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-5 g-2 g-lg-3 d-flex justify-content-center">
          <form onSubmit={tObj.submitHandler}>
            <div className="mb-3">
              <label>
                <HeadingBuilder
                  containerType  = "form-label fw-bold"
                  headingText    = {t('global.label.username')} 
                  // Username
                  labelHtmlFor   = "identifier_sign_in"
                  tippyAlertText = {t('signin.username.tippyContent')}
                  tippyText      = {t('signin.username.tippyContent')}
                  forceShowAlertText = "1"
                />
              </label>
              <input
                autoFocus
                className="form-control"
                type="text"
                style={{ textTransform: "lowercase" }}
                id="identifier_sign_in"
                name="identifier"
                autoComplete="username"
                maxLength={Constants.IDENTIFIER_MAX_LENGTH}  // 30
                onChange={handleChange}
                value={formData.identifier}
              /> <br/>
            { reason === 'goto_pwreset'
              ? <>
              <label>
                <HeadingBuilder
                  containerType  = "form-label fw-bold"
                  headingText    = {t('shopper.email.label')} 
                  // Email
                  labelHtmlFor   = "pwResetEmail"
                  tippyAlertText = {t('signin.email.tippyContent')}
                  tippyText      = {t('signin.email.tippyContent')}
                  forceShowAlertText = "1"
                />
              </label>
              <input
                className="form-control"
                type="text"
                id="pwResetEmail"
                name="email"
                autoComplete="email"
                maxLength={Constants.EMAIL_MAX_LENGTH}  // 60
                onChange={handleChange}
                value={formData.email}
              /> <br/>
              </>
              : <>
              <label>
                <HeadingBuilder
                  containerType  = "form-label fw-bold"
                  headingText    = { t('global.label.password') }
                  // Password
                  labelHtmlFor   = "password_sign_in"
                  tippyAlertText = {t('shopper.password.tippyContent')}
                  tippyText      = {t('shopper.password.tippyContent')}
                  forceShowAlertText = "1"
                />
              </label>
              <input
                className="form-control"
                type="password"
                id="password_sign_in"
                name="password"
                autoComplete="current-password"
                onChange={handleChange}
                value={formData.password}
              /> <br/>
              </>
            }
            { reason === 'pwreset'
              ? <>
                  <label>
                    <HeadingBuilder
                      containerType  = "form-label fw-bold"
                      headingText    = {t('shopper.password_confirmation.label')}
                      // Password Confirmation
                      labelHtmlFor   = "password_conf_sign_in"
                    />
                  </label>
                  <input
                    className="form-control"
                    type="password"
                    id="password_conf_sign_in"
                    name="password_confirmation"
                    autoComplete="current-password"
                    onChange={handleChange}
                    value={formData.password_confirmation}
                  /> <br/>
                </>
              : null
            }
              <input
                type='submit'
                className="btn btn-primary"
                value='Submit'
                disabled={tObj.submitIsDisabled}
              />
            </div>
          </form>
        </div>
        { reason === 'fresh'
          ? <HeadingBuilder
              Size           = 'small'
              headingText    = {t('signin.forgotPassword.label')}
              // Forgot Password'
              buttonAction   = {handleForgotPasswordClick}
              goButtonText   = {Constants.GET_ICON_RT_ARROW}
              tippyText      = {t('signin.forgotPassword.tippyContent')}
              tippyAlertText = {t('signin.forgotPassword.tippyContent')}
            />
          : null
        }
        <div className="container" ref={errorsListRef}>
          <div className="row row-cols-1 d-flex justify-content-center">
            <ul>
              {errorsList}
            </ul>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Signin;
