import cloneDeep from 'lodash/cloneDeep';
// import _ from 'lodash';

import React, { useContext, useEffect, useRef } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import CircleShoppers from './CircleShoppers';
import HeadingBuilder from '../components/HeadingBuilder';
import CircleInvitations from './CircleInvitations';
// import Trips from './Trips';
import styles from '../css/trTdFirstChild.module.css';

const Circle = ({argsId = null}) => {
  const { signedIn, shopper, circles, circleShoppers,
          plans,
        } = useContext(ShopperContext);

  const {id} = useParams();
  console.log('Circle.js id: ', id)
  const { is_shopper_a_circle_admin, getTimeInSeconds } = useUtils();

  const { tableShow, tableData } = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const topOfPageRef = useRef(null);

  const [searchParams] = useSearchParams();
  const timeInvitedShopper =
    searchParams.get(Constants.INVITE_TIME_QUERY_LABEL + '0' ) || 1;
  const timeInvitedLeader =
    searchParams.get(Constants.INVITE_TIME_QUERY_LABEL + '2' ) || 1;

  const topOfCShoppersRef = useRef(null);
  const topOfCInvitesRef  = useRef(null);
  useEffect(() => {
    if (signedIn) {
      // It's possible we are here via the 'navigate(-1)' logic in BackButton:
      // http://localhost:4000/circles/0192a07b-2f27-7664-945e-a04ccf6d5c43
      // And yet we may have recently deleted that same circle such that this
      // url will fail by virtue of navigating using an invalid 'id'.
      // It would be nice if we could check for a valid url while setting up the
      // BackButton, but React seems ornery about showing the history stack.
      // @react-navigation/native might help, but it's not installed.
      // So, check that the 'id' param is for a valid circle.

      // Another possibility is we land here after issuing an invitation.
      // The url will have a query parameter based on the flavor of invitation.
      // If this useEffect notices that the query url was created less than
      // five seconds ago it will shift focus to the relevant part of the page.
      const circle = circles.find((c) =>  c.id === id ) || {};
      const currTimeInSeconds = getTimeInSeconds();

      if (currTimeInSeconds - timeInvitedShopper < 5) {
        topOfCShoppersRef.current.focus();
      }
      else if (currTimeInSeconds - timeInvitedLeader < 5) {
        topOfCInvitesRef.current.focus();
      }
      else if (circle.hasOwnProperty('id')) {
        topOfPageRef.current.focus();
      } else {
        navigate('/circles');
      }
    }
  }, [signedIn, topOfPageRef, circles, id, navigate,
    getTimeInSeconds,
    timeInvitedShopper, topOfCShoppersRef,
    timeInvitedLeader, topOfCInvitesRef,
  ]);

  // This logic from Trip.js (see comments there.)
  if (circles.length === 0) {
    // 230427 getting this warning:
    // react_devtools_backend.js:2655 Warning: Cannot update a component (`BrowserRouter`) while rendering a different component (`Circle`). To locate the bad setState() call inside `Circle`, follow the stack trace as
    // So, will disable the navigate for now and put message to console.log
    console.log('Note: getting "circles.length" === 0')
    //navigate('/circles/')
    // This is progress. At least something comprehensible happens upon pressing
    // a browser reload icon.
  }

  console.log("circle plans", plans)

 if (signedIn) {
  console.log('Circle.js circles-orig: ', circles)
  const the_circles = cloneDeep(circles)  // TODO: I bet cloneDeep is unnecessary
  console.log('Circle.js circles-copy: ', the_circles)

  // load "circle" with the circle that matches the id param from url
  const circle = the_circles.find((c) => {
    console.log('find circle: ', c)
    console.log('find circle.id: ', c.id)
    console.log('find circle boolean: ', c.id == id)
    return c.id == id  // TODO: get warning for '==', but '===' fails - quiesce?
  }) || {} ;

  console.log('find circle2: ', circle)

  // We should have only gotten to this component by a valid link. But there is
  // also the case of a shopper removing themself from a circle via its (this)
  // Circle detail page. Upon re-rendering, that id no longer matches a circle
  // in the shopper's updated list of circles as saved in state. That causes
  // the 'circleIsFalse' check here to navigate to a list of shopper's circles
  // rather than persist in showing the detail page of a circle the shopper is
  // no longer a member of.
  // Also possible: someone types 'http://circles/1887' at the browser.
  // In that case, 'circle' will have no value.
  const circleIsFalse = Object.is(circle,null) || !circle.hasOwnProperty('id');
  console.log({circleIsFalse});
  if (circleIsFalse) {
    // looks safe to go to '/circles' - for a new user with no circles, /circles
    // looks mostly empty with a header of: Circles for: <shopper identifier>
    navigate('/circles');
    // TODO: need fix for this warning: react_devtools_backend.js:2655 Warning:
    // Cannot update a component (`BrowserRouter`) while rendering a different
    // component (`Circle`).
    // Maybe just live with this since it's a warning, not an error?
    return (<></>)
  }

  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: circle.id, shopper_id: shopper.id
  });

  const if_shopper_is_circle_leader =  circle.leader_id === shopper.id;
// admin_count: null
// billee_id: 6
// city: null
// country: "US"
// county: null
// postal_code: null
// detail: null
// id: 9
// identifier: "x3"
// img_url: null
// label: "x4"
// leader_id: 6
// plan: {id: 1, identifier: 'free00', label: 'Free plan - testing, 220819', detail: null, max_shoppers: null, …}

  const circle_invitations = [...circle.open_invites]
  console.log("circle_invitations", circle_invitations)

  const circle_accepted_invitations = [...circle.accepted_invites]
  console.log("circle_accepted_invitations", circle_accepted_invitations)

  // find plan object of circle
  const plan = plans.find((p) => {
    return p.id == circle.plan_id
  }) || {};
  console.log("plan, circle.plan_id", plan, circle.plan_id)

  // present that circle's members; circle admin sees button allowing altering circle membership
  return (
    <>
      <article ref={topOfPageRef} tabIndex="-1">

      <HeadingBuilder
        Size        = 'h3'
        headingText = {t('circleId.formPart.headlineLabel')}
        value       = {circle.identifier}
        // Circle Details: oipuy
      />
      <Table responsive striped bordered hover size="sm" style={tableShow}>
        <thead>
        </thead>
        <tbody className={styles.trTdFC} style={tableData} >
          <tr>
            <td>
              {/* Shopping Circle ID */}
              {t('circle.identifier.label')}
            </td>
            <td>
              {circle.identifier}
            </td>
          </tr>
          <tr>
            <td>
              {/* Description */}
              {t('circle.label.label')}
            </td>
            <td>
              {circle.label}
            </td>
          </tr>
          <tr>
            <td>
              {/* Zip Code */}
              {t('circle.postal_code.label')}
            </td>
            <td>
              {circle.postal_code}
            </td>
          </tr>
          <tr>
            <td>
              {/* Time Zone */}
              {t('circle.time_zone.label')}
            </td>
            <td>
              {circle.time_zone}
            </td>
          </tr>
          <tr>
            <td>
              <HeadingBuilder
                containerType  = 'container-input-label'
                headingText    = {t('circle.share_sku_basic.label')}
                  // Share basic Sku info
                tippyAlertText = {t('circle.share_sku_basic.tippyContent')}
                tippyText      = {t('circle.share_sku_basic.tippyContent')}
                // A 'yes' value here allows ShopWeAll to copy basic information...
              />
            </td>
            <td>
              {circle.share_sku_basic ? 'Yes' : 'No'}
            </td>
          </tr>
        { if_shopper_is_circle_leader
        ? <tr>
            <td>
              {/* Plan */}
              {t('circle.plan.label')}
            </td>
            <td>
              {/* {circle.plan_id} */}
              {plan.label}
            </td>
          </tr>
        : null
        }
          <tr>
            <td>
              {/* Sales Tax Rate */}
              {t('circle.tax_rate.label')}
            </td>
            <td>
              {circle.tax_rate ? <span>{`${circle.tax_rate}`}</span> : <span>0</span>}%
            </td>
          </tr>
          <tr>
            <td>
              {/* Food Sales Tax Rate */}
              {t('circle.tax_rate_food.label')}
            </td>
            <td>
              {circle.tax_rate_food ? <span>{`${circle.tax_rate_food}`}</span> : <span>0</span>}%
              {/* TODO: Below is abusive: "styling should be handled with CSS."
              Doing this to pad out content of "detail" to shift second
              column of each table row (value of attr) further to left. */}
              &emsp; &emsp; &emsp; &emsp; &emsp;
              &emsp; &emsp; &emsp; &emsp; &emsp;
              &emsp; &emsp; &emsp; &emsp; &emsp;
              &emsp; &emsp; &emsp; &emsp; &emsp;
            </td>
          </tr>
        </tbody>
      </Table>
      { if_shopper_is_circle_admin &&
          <HeadingBuilder
            tippyAlertText = {t('circle.editCircleButton.tippyContent')}
            tippyText      = {t('circle.editCircleButton.tippyContent')}
            linkUrl        = {`/circles/${circle.id}/edit`}
            goButtonText   = {Constants.GET_ICON_RT_ARROW}
            headingText    = {t('circle.editCircleButton.label')}
                           // Edit Circle Settings (requires admin)
          />
      }
      { if_shopper_is_circle_leader && <br/> }
      { if_shopper_is_circle_leader &&
          <HeadingBuilder
            tippyAlertText = {t('circleDeleteForm.formPart.tippyContent')}
            tippyText      = {t('circleDeleteForm.formPart.tippyContent')}
            linkUrl        = {`/circles/${circle.id}/delete`}
            goButtonText   = {Constants.GET_ICON_RT_ARROW}
            headingText    = {t('circleDeleteForm.formPart.triggerLinkLabel')}
                        // Invite New Leader / Delete Circle (requires 'leader')
          />
      }
      { if_shopper_is_circle_admin ? <hr/> : null }

      <Table responsive>
        <thead>
        </thead>
        <tbody>
          <tr>
            <td>
              <HeadingBuilder
                containerType  = 'oneline-container-right'
                headingText    = {t('circle.circleSkusButton.label')}
                linkUrl        = {`/circle/skus/${circle.id}`}
                tippyAlertText = {t('circle.circleSkusButton.tippyContent')}
                tippyText      = {t('circle.circleSkusButton.tippyContent')}
                               // Circle's Skus
              />
            </td>
            <td>
              <HeadingBuilder
                containerType  = 'container-left'
                headingText    = {t('circle.circleTripsButton.label')}
                linkUrl        = {`/circle/trips/${circle.id}`}
                tippyAlertText = {t('circle.circleTripsButton.tippyContent')}
                tippyText      = {t('circle.circleTripsButton.tippyContent')}
                               // Circle's Trips
              />
            </td>
          </tr>
        </tbody>
      </Table>
      </article>
      <hr />
      <article ref={topOfCShoppersRef} tabIndex="-1" >
        <CircleShoppers
          circle_id={circle.id}
          circle_identifier={circle.identifier}
          circle_leader_id={circle.leader_id}
          open_invites={circle_invitations}
          accepted_invites={circle_accepted_invitations}
        />
      </article>
      <article ref={topOfCInvitesRef} tabIndex="-1" >
        <hr />
        { circle.open_invites.length > 0
        ? <CircleInvitations
            circle_id={circle.id}
            circle_identifier={circle.identifier}
            circle_leader_id={circle.leader_id}
            open_invites={circle_invitations}
          />
        : <>&nbsp;</>
        // Wacky: Using ternary to present this component at least gets the
        // headline "Outstanding Invitations" to appear if focus on the ref
        // after inviting an admin to become leader of the circle. The whole
        // component seems to appear if there is already an invitation.
        // Using short-circuit '&&' approach means headline does not appear
        // if no outstanding invitations. But the whole component is visible
        // after 'focus' if there was already an invitation.
        // Ideally the whole 'Outstanding Invitations' componenent would
        // show (focus) at the top of the page regardless of prior invitations.
        }
      </article>
    </>
  )
 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}
export default Circle
      // <hr />
      // <div>
      //   <Trips circle_id={circle.id} circle_identifier={circle.identifier}/>
      // </div>