// Don't apply 'HeadingBuilder' for this component's 'Tippy contents' (table data)
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants'
import { tax_multiplier, showRoundedAbsAmt, findAbsAmtAsPct } from '../utils';
import { useLookups, usePricing, usePhrasing, useFormats } from "../hooks/useSkuUtils";
import { useStyles } from "../hooks/useStyles";
import { OutlayAmt } from '../components/OutlayAmt';
      
import { NumericFormat } from 'react-number-format';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

import styled from 'styled-components';
import HeadingBuilder from './HeadingBuilder';
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? Constants.O_BOX_GREEN : Constants.O_BOX_PINK};
  border: none;
  &.hover {
    background: #e7e7e7; color: black;
    transition: 0.3s;
  }
  /* color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px; */
`;

const Order100Button = styled.button`
  background: lightblue;
  border: none;
`;

export const OrderItem = ({ order,
                            trip,
                            sku,
                            showIdentifier,
                            showUnitCost,
                            skuAmtForShopper,
                            skuAmtForCircle
                           }) => {
  console.log("oi orig order", order);
  console.log("oi orig sku", sku);
  console.log("skuAmts for Shopper, Circle:", [skuAmtForShopper, skuAmtForCircle].join());
  const { shopper, prices, showOutlayWithTax, changeOrderItem } = useContext(ShopperContext);
  console.log("pricesX", prices)
  console.log("showOutlayWithTaxX", showOutlayWithTax)
  const { t } = useTranslation();
  const { getPrice, getUnitCost, chooseDecimalScaleQty } = usePricing();
  const { editBoxStyle } = useStyles();
  const { altBorderTopColor } = useStyles();
  const { weightAndUnitsOfSku, noDotZero } = usePhrasing();
  const { lookup_category } = useLookups();
  const { checkDecimalInput } = useFormats();

  const isDiscrete =  sku.type == "DiscreteItem" ? true : false;
  const isGranular =  sku.type == "GranularItem" ? true : false;
  const isWeighty  =  sku.type == "WeightyItem" ? true : false;

  const [plusButtonIsGreen, setPlusButtonIsGreen] = useState(true);
  const [orderAmtButtonIsPink, setOrderAmtButtonIsPink] = useState(false);
  const [unitQty, setUnitQty] = useState( isGranular ? 0 : skuAmtForShopper );
  // Maybe this needs the original: Math.round(skuAmtForShopper * 100)/100
  
  // FIX ME: getting "NaN%" for Circle Amt for peanut butter: uq 1, uc 2
  const [absAmtAsPct, setAbsAmtAsPct] = useState(
    findAbsAmtAsPct({topNum: skuAmtForShopper, sku})
    // I expect it's best to leave this thing as a number, so no ".toString()"
  );
  console.log("oo_aqap", absAmtAsPct);
  console.log("osuc1", sku.unit_count);

  const sku_price    = getPrice({sku_id: sku.id, trip, prices});
  console.log("circamt", typeof skuAmtForCircle, skuAmtForCircle);
  const circleAmt    = skuAmtForCircle + skuAmtForShopper;
  const circleOutlayBeforeTax =
    findAbsAmtAsPct({topNum: circleAmt * sku_price, sku}) / 100;

  // Could anticipate/handle instances where the form field is "empty" via:
  //if (event.target.value == 0 || event.target.value == "" || event.target.value == "NaN")

  // Every orderItem reflects a record in the order_items table. What
  // matters here is that we capture changes in abs_amount of a sku,
  // PATCH them in.

return React.useMemo(() => {
  const circleOutlay = circleOutlayBeforeTax *
    (showOutlayWithTax
      ? tax_multiplier({
          tax_type: (sku.tax_type || 0),
          trip,
        })
      : 1
    );

  const handleClick100Button = (e) => {
    if (e.detail === 0) {
      // a return event occured
      // i.e. it's likely that shopper pressed the Enter key.
      // Intercept 'Enter' here. Otherwise, Enter acts as a click
      // and bumps the amt by another 100%.
      return;
    }

    // Shopper might clear out contents of unitQty/absAmtAsPct.
    // 'Click100' button should treat this empty input field as amt 0.
    if (isDiscrete) {
      const currQtyFloat = parseFloat(unitQty) || 0;
      const qty100Amount = parseFloat(sku.unit_count);
      // TODO: I'm curious about why Discrete doesn't use 'String( )' here:
      e.target.value = currQtyFloat + qty100Amount;
      handleUnitQtyChange(e);
    }
    else if (isWeighty) {
      const currQtyFloat = parseFloat(unitQty) || 0;
      const qty100Amount = parseFloat(sku.typical_weight);
      e.target.value = String(currQtyFloat + qty100Amount);

      handleUnitQtyChange(e);
    }
    else { // isGranular
      const currAmtFloat = parseFloat(absAmtAsPct) || 0;
      const qty100Amount = 100;
      e.target.value = String(currAmtFloat + qty100Amount);
      handleAbsAmtAsPctChange(e);
    }

    setOrderAmtButtonIsPink(true);
  }

  const handleClick = (e) => {
    setPlusButtonIsGreen(true);
    setOrderAmtButtonIsPink(false);
    console.log("spl hc sku1", order);
    console.log("spl hc sku1.5", order.id);
    console.log("spl hc sku2", e.target.value);
    const orderItem = {
      abs_amount: isGranular ?  parseFloat(absAmtAsPct) / 100  :  unitQty
    };
    changeOrderItem(orderItem, order.id);
  }

  const handleUnitQtyChange = (e) => {
    const newUnitQty = checkDecimalInput( {numInput: e.target.value} )

    setOrderAmtButtonIsPink(true);
    setPlusButtonIsGreen(false);
    console.log("huqc nuq", newUnitQty);
    setUnitQty(() => newUnitQty);
    if (isDiscrete || isWeighty) {
        setAbsAmtAsPct( () => findAbsAmtAsPct({topNum: newUnitQty, sku}) )
    }
  }

  const handleAbsAmtAsPctChange = (e) => {
    // Remove any chars that are not digits.
    //const regex = /\D/g;
    // per https://bobbyhadz.com/blog/react-only-number-input
    // const newAbsAmtAsPct = e.target.value.replace(/\D/g, '');
    // FIXME: a user was triggering bad behavior by inserting '.' into value.
    // 33.3 was being treated as 333%, not 33.3%
    const newAbsAmtAsPct = checkDecimalInput( {numInput: e.target.value} )

    setOrderAmtButtonIsPink(true);
    setPlusButtonIsGreen(false);

    console.log("aqap1", absAmtAsPct);
    console.log("haqapc naqap", newAbsAmtAsPct);
    setAbsAmtAsPct( () => newAbsAmtAsPct )
    console.log("aqap2", absAmtAsPct);
  }

  // There was an error message about lists missing keys so I tried supplying
  // the list via this function. But then I tried putting the array there
  // again and it didn't complain. So, comment this out for now, keep watch.
  // const unitsForPrice = ({sku}) => {
  //   return [ <wbr/>, '/' , sku.units ]
  // }
  // <wbr/> (word break) allows "/lb" to appear below the price
  // (was for Price column)

  // We'll also normalize the "adjusted amount" values to show the percent
  // of 'unit_count' or 'typical_weight' that the order represents.
  // Thus the percentage we
  // show fits the "have we reached 100% of the order" expectation for that
  // value. Otherwise it shows scary percentages like 450% (for weighty) that
  // would represent purchasing 4.5 lbs (or whatever units) of the item.
  // If 'typical_weight' is 8, then we'll see values like 4.5 / 8 = 56%
  // The Rails model requires typical_weight values to be > 0.
  const adjustedCircleAmt =
    isDiscrete
      ? circleAmt / sku.unit_count
      : (isWeighty && sku.typical_weight)
        ? circleAmt / sku.typical_weight
        : circleAmt;

  const adjustedAbsAmtAsPct =
    (isWeighty && sku.typical_weight)
      ? absAmtAsPct / sku.typical_weight
      : absAmtAsPct;

// How React onChange event handlers work - with code example
// https://sebhastian.com/react-onchange/

  // checkbox advice per https://www.robinwieruch.de/react-checkbox/
  // button advice per https://www.robinwieruch.de/react-button/
  // https://upmostly.com/tutorials/pass-a-parameter-through-onclick-in-react
  return (
    <tr style={altBorderTopColor} key={'oi' + order.id}>

<td>
  { showIdentifier
    ?
      sku.identifier.length > Constants.MAX_SKU_IDENTIFIER_CHARS_BEFORE_TIPPY
        ? < HeadingBuilder
            containerType  = 'ellipsis fw-bold'
            headingText    = {sku.identifier}
            tippyAlertText = {sku.identifier}
            tippyText      = {sku.identifier}
          />
        : <b>{sku.identifier}</b>
        
    : < HeadingBuilder
        headingText    = '.'
        tippyAlertText = {sku.identifier}
        tippyText      = {sku.identifier}
        forceShowAlertText = '1'
      />
  }
</td>
<td>{sku.brand}</td>
<td>
  <Tippy content= { lookup_category({category: sku.category}) } >
    <Link to={`/skus/${sku.id}`}>
      <span>{sku.label}</span>
    </Link>
  </Tippy>
  {/* Let's add weight info for non-discrete items after the label: */}
  {
    weightAndUnitsOfSku({
      sku,
      showWeightAsSkuEditLink: true
    })
  }
</td>
<td>{sku.store_label}</td>

<td>
  <b>{shopper.shortname}</b>
</td>
<td>
  <Tippy content= {t('orderItem.blue_unit_count.tippyContentA')
    + Constants.O_COL_SYM + t('orderItem.blue_unit_count.tippyContentB')} >
{/* "Click the blue button to raise your order by one full sku.
     Then click the pink 'O' sign to add your order to your Circle's orders." */}
  {
    // "Unit Count" || Sku has this many units
    <Order100Button type="button" onClick={handleClick100Button}>
      {isDiscrete
        ? <b>{noDotZero({num: sku.unit_count})}</b>
        : isWeighty
          ? <b>{sku.typical_weight}</b>
          : <span>--</span>
      }
    </Order100Button>
  }
  </Tippy>
</td>
<td>
    ${ (Math.trunc(sku_price * 100) / 100).toFixed(2).toString() }
    { isWeighty && [ <wbr key={'wbr' + order.id}/>, '/' , sku.units ] }
    {/* <wbr/> allows "/lb" to appear below the price */}
</td>
<td>
  {   
  // "UnitQty" || Shopper wants this many units
    (isDiscrete || isWeighty)
      ? <span><NumericFormat displayType="input"
          type="text"
          name="unitQty"
          onChange={e => handleUnitQtyChange(e)}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleClick(e)
            }
          }}
          value={unitQty}
          decimalScale={ chooseDecimalScaleQty({sku}) }
          style={ editBoxStyle({ cellQty: unitQty,
            changeColorOfBox: orderAmtButtonIsPink,
            editBoxColor: Constants.O_BOX_PINK
          }) }
        /></span>

      : <span style={{ width: "70px" }} >--</span>
  }

  {/* For weighty items, helpful to show the unit of measure with the UnitQty.
  The value (e.g. "lb") shows below if the SWA window isn't wide enough.
  It shows beside (after) if the screen is wide enough. */}
  { isWeighty && sku.units }
</td>
<td>
  {
    showUnitCost
      ? <span>
          {/* TODO: Could change color and/or supply tooltip suggesting to use
          different units if getUnitCost value begins with '0.00' */}
          ${ getUnitCost({sku, sku_price}) }
            /{sku.units.replace(/[(]s[)]$/, '')}
            {/* This is unit cost, so we replace units like "battery(s)"
            with "battery" */}
        </span>
      : <span>.</span>
  }
</td>
<td>
    {
        // "AbsAmt" ||
        (isDiscrete || isWeighty)
            ? <span style={{ width: "70px" }} >
                {showRoundedAbsAmt({ adjustedAbsAmtAsPct }) + "%"}
              </span>
            : <span><NumericFormat displayType="input"
                type="text"
                name="absAmtAsPct"
                onChange={e => handleAbsAmtAsPctChange(e)}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    handleClick(e)
                  }
                }}
                value={absAmtAsPct}
                decimalScale={ chooseDecimalScaleQty({sku}) }
                style={ editBoxStyle({ cellQty: absAmtAsPct,
                  changeColorOfBox: orderAmtButtonIsPink,
                  editBoxColor: Constants.O_BOX_PINK
                }) }
              />%</span>
    }
</td>
<td className = "oneline-container">
  <OutlayAmt
    trip        = {trip}
    sku         = {sku}
    sku_price   = {sku_price}
    order       = {order}
    decimal_amt = {
      findAbsAmtAsPct({
        topNum: isGranular ? parseFloat(absAmtAsPct)/100 : unitQty
        ,sku
      }) / 100
    }
    showOutlayWithTax = {showOutlayWithTax}
  />
</td>
<td>
    {/* "O" button: */}
    {/* Click the pink "O" sign to update the database with the amount you want of the product. */}
    <Tippy content= {t('orderItem.o_col.tippyContentA') + Constants.O_COL_SYM + t('orderItem.o_col.tippyContentB')} >
      <Button type="button" primary={plusButtonIsGreen} value={order.id} onClick={handleClick}>{Constants.O_COL_SYM}</Button>
    </Tippy>
</td>
<td>{showRoundedAbsAmt({ adjustedAbsAmtAsPct: adjustedCircleAmt * 100 }) + "%"}</td>
<td>{ "$" + circleOutlay.toFixed(2).toString() }</td>
{/* TODO: change "$" to currency symbol */}

    </tr>
  )
}, [
    showIdentifier,
    showUnitCost,
    order,
    shopper,
    trip,
    sku,
    plusButtonIsGreen,
    orderAmtButtonIsPink,
    t,
    showOutlayWithTax,
    editBoxStyle,
    altBorderTopColor,
    lookup_category,

    changeOrderItem,
    absAmtAsPct,
    weightAndUnitsOfSku,
    checkDecimalInput,
    chooseDecimalScaleQty,
    getUnitCost,
    noDotZero,

    isDiscrete,
    isGranular,
    isWeighty,
    circleOutlayBeforeTax,
    circleAmt,
    sku_price,
    unitQty
]);
}

export default OrderItem;
