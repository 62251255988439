import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { Link } from 'react-router-dom'
import * as Constants from '../constants';
import SwaOverview from '../components/SwaOverview';
import HeadingBuilder from '../components/HeadingBuilder';
import Invitations from './Invitations';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

const Home = () => {
    const { shopper, signedIn, invitations,
          } = useContext(ShopperContext)

    const { t } = useTranslation();

    const [showOverview, setShowOverview] = useState(false);
    const handleOverviewClick = event => {
      setShowOverview(current => !current);
    };

    const [showInvitations, setShowInvitations] = useState(false);
    const handleInvitationsClick = event => {
      setShowInvitations(current => !current);
    };

    const shopweallOverviewText =
        t('global.label.shopWeAll') + Constants.TM_SYM + ' '
      + t('faq.overview.label');

    if (signedIn) {
        console.log('invitation status', invitations)
        const shopper_has_NotResponded_invitation = invitations.some(
          i => i.status === 'NotResponded');
    
        const invitationsButtonText =
          shopper_has_NotResponded_invitation
            ? t('home.invitationsButton.hasNotRespondedInvite')
              // "See invitation(s) for a Shopping Circle!"
            : invitations.length > 0
              ? t('home.invitationsButton.hasRespondedToAllInvites')
                // "You have responded to all Invitation(s) - Click to view"
              : t('home.invitationsButton.hasNoInvites');
                // "You have no Shopping Circle invitations."
    
        const invitationsButtonTippyText =
          shopper_has_NotResponded_invitation
            ? t('home.invitationsButton.has_NotRespondedTippyContent')
              // You have not responded to one or more Invitations....
            : invitations.length > 0
              ? t('home.invitationsButton.hasRespondedTippyContent')
                // You have no outstanding Invitations...
              : t('home.invitationsButton.hasNoInvitesTippyContent')
                // You don't have any invitations to join a Shopping Circle.

        const headlineName =
          (shopper.firstname && shopper.lastname)
            ? shopper.firstname + ' ' + shopper.lastname
            : (shopper.firstname || shopper.lastname)
              ? (shopper.firstname || shopper.lastname)
              : '...'

        const headlineInit =
          t('home.formPart.headlineLabel')
            + headlineName
            + `",`;

        return (
            <article>
                <HeadingBuilder
                  Size = "h1"
                  headingText = {headlineInit}
                  value = {shopper.shortname}
                  // Home for "Uu Ii", uuii
                  videoUrl = "video/home-screen.mp4"
                />

                <HeadingBuilder
                  tippyAlertText = {t('home.editProfileButton.tippyContent')}
                  tippyText      = {t('home.editProfileButton.tippyContent')}
                  linkUrl        = {`/shoppers/${shopper.id}`}
                  goButtonText   = {Constants.GET_ICON_RT_ARROW}
                  headingText    = {t('home.editProfileButton.label')}
                                 // Your Profile: View, Change or Delete
                />
                <br/>
                { showOverview
                  ? <SwaOverview
                      handleOverviewClick = { handleOverviewClick }
                    />
                  : <HeadingBuilder
                      headingText    = {shopweallOverviewText}
                      // ShopWeAll&#8482; Overview
                      buttonAction   = {handleOverviewClick}
                      goButtonText   = {Constants.GET_ICON_RT_ARROW}
                      tippyText      = {t('home.swaOverviewButton.tippyContent')}
                      tippyAlertText = {t('home.swaOverviewButton.tippyContent')}
                    />
                }
                <br/>
                { showInvitations && <><hr/></> }
                <br/>
                { showInvitations
                  ? <Invitations
                      handleInvitationsClick = { handleInvitationsClick }
                    />
                  : invitations.length > 0
                    ? <HeadingBuilder
                        headingText    = {invitationsButtonText}
                        buttonAction   = {handleInvitationsClick}
                        goButtonText   = {Constants.GET_ICON_RT_ARROW}
                        tippyText      = {invitationsButtonTippyText}
                        tippyAlertText = {invitationsButtonTippyText}
                      />
                    : <HeadingBuilder
                        headingText    = {invitationsButtonText}
                        linkUrl        = {'/circles/'}
                        goButtonText   = {Constants.GET_ICON_RT_ARROW}
                        tippyText      = {invitationsButtonTippyText}
                        tippyAlertText = {invitationsButtonTippyText}
                      />
                }
    
            </article>
        )
    } else {
      return (
        <div className="container-fluid mt-5 ">
          <div className='homeBkgrnd'></div>
            <h1 className="fs-1 white fw-light">
              {t('home.signedOut.mainHeadline')}
    {/* A Shopping List tool for groups, to buy in bulk and split the purchases. */}
            </h1>
          <div className="container mt-6 p-5">
            <div className='fs-6' >
                <HeadingBuilder
                  Size = "h6"
                  headingText = {t('home.signedOut.bFranklinText')}
                  value = {t('home.signedOut.bFranklinValue')}
                  // Benjamin Franklin: A penny saved is a penny earned.
                />
                <HeadingBuilder
                  Size = "h6"
                  headingText = {t('home.signedOut.hKellerText')}
                  value = {t('home.signedOut.hKellerValue')}
                  // Helen Keller: Alone we can do so little, together we can do so much.
                />
            </div>

            <p className='fs-3'>
              {t('home.signedOut.pitch10')}
              {/* We've made it easy to share shopping duty with your friends. */}
            </p>
            <p className='fs-3'>
              {t('home.signedOut.pitch20')}
              {/* (Or take delivery???) */}
            </p>
            <p className='fs-3'>
              {t('home.signedOut.pitch30')}
              {/* Shop with them, for them, by them. */}
            </p>
            <p className='fs-3'>
              {t('home.signedOut.pitch40')}
              {/* Save time, money, frazzle, community. */}
            </p>
            <p className='fs-3'>
              {t('home.signedOut.pitch50')}
              {/* Check it out! */}
            </p>
          </div>

          <div className="container">
            <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3 d-flex justify-content-center">
              <div className="col d-flex justify-content-center">
                <div className="circle blue_circle p-3  d-flex align-items-center justify-content-center text-center">
                  <h3><Link to={'/signup'}>{t('home.signedOut.signUp')}
                    {/* SIGN UP */}
                  </Link></h3>
                </div>
              </div>
              <div className="col d-flex justify-content-center d-flex justify-content-center ">
                <div className="circle light_creme_circle p-3 d-flex align-items-center justify-content-center text-center">
                  <h3><Link to={'/signin/fresh'}>{t('home.signedOut.signIn')}
                    {/* SIGN IN */}
                  </Link></h3>
                </div>
              </div>
              <div className="col d-flex justify-content-center d-flex justify-content-center">
                <div className="circle dark_creme_circle p-3 d-flex align-items-center justify-content-center text-center">
                  <h3 className="white">{t('home.signedOut.save')}
                    {/* SAVE!!! */}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default Home;
