//src/context/shopper.js
//import cloneDeep from 'lodash/cloneDeep';
//import _ from 'lodash';
import React, { useState, useMemo, useCallback, useEffect } from 'react';

//const Context = createContext<{ value: number }>({ value: 1 });
//const Context2 = createContext<{ value: number }>({ value: 1 });

// context:
const ShopperContext = React.createContext();
ShopperContext.displayName = 'ShopperContext';

function mkHtmlListOfErrorMessages() {
  // invoke with a list of error strings, e.g.: mkHtmlListOfErrorMessages(...data.errors)
  const args = [...arguments]
  console.log('mhloem', args)

  // Instead of:
  // const errorLis = data.errors.map(err => <li className="stdError" key={err} >{err}</li>)
  // we use 'Set' to create unique list of errors so that "key={err}" is
  // reliably different.
  // Maybe create a histogram of the errors, i.e. a count of any duplicates,
  // and present the deduped list along with its count if it occurs > 1 time?
  return [...new Set(args)].map(err => <li className="stdError" key={err} >{err}</li>)
}

// Scoop up unanticipated errors (e.g. 500's) for display with 'errorList'
// Modifies incoming 'rdat' (data) (Note: no special meaning to "rdat")
function includeOtherErrors(rdat) {
  const data_other = [ rdat.status, rdat.error ].filter(d => d != null).join(" ");
  if (data_other.length > 0) {
      rdat.errors ||= [];
      rdat.errors.push(data_other);
  }
}


// provider component:
function ShopperProvider({ children }) {
    const [shopper, setShopper] = useState({});
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    const [latestPausedText, setLatestPausedText] = useState('');
    const resetLatestPausedText = useCallback( (lpt) => {
      setLatestPausedText(lpt)
    })

    const [signedIn, setSignedIn] = useState(false);  // NN, last one, at 4:00

    const [spAuthIsActive, setSpAuthIsActive] = useState(false);

    const [stripeIsActive, setStripeIsActive] = useState(false);
    const [stripeIsLive, setStripeIsLive] = useState(false);  // set 'false' for testing
    const [invitations, setInvitations] = useState([])

    const [circleShoppers, setCircleShoppers] = useState([])
    // TODO: this from https://stackoverflow.com/a/68684304
    //useEffect(() => {
      //console.log('B addCircleShopper4', circleShoppers);
      //console.log('B addCircleShopper3', circleShoppers);  // throwaway line; helps jump to aCS3
    //}, [circleShoppers]);

    const [circle, setCircle] = useState({});
    const [circles, setCircles] = useState([]);
    const [clientReferenceId, setClientReferenceId] = useState('');


    const [trips, setTrips] = useState([]);
    const [lastTripSeenId, setLastTripSeenId] = useState('');
    const [showShoppingList, setShowShoppingList] = useState(true);
    const setShowShoppingListToTrue = useCallback( () => {
      setShowShoppingList(true);
    }, [])
    const setShowShoppingListToFalse = useCallback( () => {
      setShowShoppingList(false);
    }, [])

    const [inSkuMenu, setInSkuMenu] = useState(false);
    const setInSkuMenuToTrue = useCallback( () => {
      setInSkuMenu(true);
    }, [])
    const setInSkuMenuToFalse = useCallback( () => {
      setInSkuMenu(false);
    }, [])

    const [skus, setSkus] = useState([]);
    const [foundSkus, setFoundSkus] = useState([]);
    const unsetFoundSkus = useCallback( () => {
        setFoundSkus([]);
    }, []);
    const [lastSkuSeen, setLastSkuSeen] = useState('');

    const [prices, setPrices] = useState([]);
    const [plans, setPlans] = useState([]);

    const [orderItems, setOrderItems] = useState([]);
    const [showOutlayWithTax, setShowOutlayWithTax] = useState(false);
    const toggleShowOutlayWithTax = useCallback( () => {
        setShowOutlayWithTax(() => !showOutlayWithTax);
        console.log("sowt", showOutlayWithTax);
    }, [showOutlayWithTax]);
    const [showOthersOrders, setShowOthersOrders] = useState(false);
    const toggleShowOthersOrders = useCallback( () => {
        setShowOthersOrders(() => !showOthersOrders);
        console.log("soo", showOthersOrders);
    }, [showOthersOrders]);
    const [showOthersZeroOrders, setShowOthersZeroOrders] = useState(false);
    const toggleShowOthersZeroOrders = useCallback( () => {
        setShowOthersZeroOrders(() => !showOthersZeroOrders);
        console.log("sozo", showOthersZeroOrders);
    }, [showOthersZeroOrders]);

    const [errorsList, setErrorsList] = useState([]);
    const addToErrorsList = useCallback( (newErrs) => {
        console.log("atel", newErrs);
        setErrorsList(newErrs);
    }, []);
    const dismissErrorsList = useCallback( () => {
        setErrorsList([]);
    }, []);

    const [errorsDoExist, setErrorsDoExist] = useState(false);
    const setErrorsDoExistToFalse = useCallback( () => {
        setErrorsDoExist(false);
    }, []);
    const setErrorsDoExistToTrue = useCallback( () => {
        setErrorsDoExist(true);
    }, []);

    const [justGotClear, setJustGotClear] = useState(false);
    const setJustGotClearToFalse = useCallback( () => {
        setJustGotClear(false);
    }, []);
    // const setJustGotClearToTrue = useCallback( () => {
    //     setJustGotClear(x => true);
    // }, []);

    // hmm, prob not for this: from https://stackoverflow.com/a/59795996 - Access dynamic nested key in JS object
    // const [checkedItemsByCircle, setCheckedItemsByCircle] = useState({});
    // const _ = require("lodash");

    // const tmpItemsByCircle = {};
    // circles.forEach(circle => skus.forEach(sku => {
    //     // TODO: must be an easier way to concentrate on the skus of a circle. For
    //     // now we'll pay the price of constantly updating the 'tmp' and doing set
    //         if (sku.circle_id == circle.id) {
    //             _.get(
    //                 tmpItemsByCircle
    //                 ,`${circle.id.toString()}.${sku.circle_id.toString()}`
    //             )
    //             ||
    //             _.set(
    //                 tmpItemsByCircle
    //                 ,`${circle.id.toString()}.${sku.circle_id.toString()}`
    //                 , false  // TODO: set value based on abs_amount
    //             )
    //         }  // else { }
    //     })
    // )

    // setCheckedItemsByCircle(x => tmpItemsByCircle);

    // const itemsAreOn = (circle_id, sku_id ) => {
    //     const tmpItemsByCirc = _.cloneDeep(checkedItemsByCircle);
    //     const currVal =
    //         _.get(
    //             tmpItemsByCirc
    //             ,`${circle_id.toString()}.${sku_id.toString()}`
    //         )

    //     _.set(
    //         tmpItemsByCirc
    //         ,`${circle_id.toString()}.${sku_id.toString()}`
    //         , currVal ? !currVal : false
    //     )

    //     setCheckedItemsByCircle( x => tmpItemsByCirc )
    // }

    // We move defining several fetch"X" functions up front so they are ready
    // when 'addCircleShopper' calls them after accepting an invitation. (It does
    // [circleShoppers, fetchCircles, fetchTrips, fetchSkus, fetchCircleShoppers]);

    // is called by fetch('/api/me')
    // We'll rebuild state of circles upon signup, signin, /api/me checks
    // and upon addCircleShopper.
    const fetchCircles = useCallback(() => {
      return fetch('/api/circles')  // gets data of circles that shopper is in
        .then(res => res.json())
        .then(data => {
            console.log("fac uc dacs", data)
            setCircles(data)
            return(data)
        })
        // .catch(error => {
        //   console.log('fzc error', error);
        //   throw error;
        // });
    }, []);

    // When shopper leaves a circle we need a listing of all of the circle's
    // order_items. This fetch invokes a 'show' for a specific circle, getting
    // the extra data of a listing of all of the circle's order_items.
    const fetchCircleOrders = useCallback((circle_id) => {
      return fetch(`/api/circles/${circle_id}`)
        .then(res => res.json())
        .then(data => {
            console.log("faci uc circle", data)
            setCircle(data)
            return(data)
        })
        // .catch(error => {
        //   console.log('fzc error', error);
        //   throw error;
        // });
    }, []);

    // gets called by fetch('/api/me')
    const fetchCircleShoppers = useCallback(() => {
        fetch('/api/circle_shoppers')
        .then(res => res.json())
        .then(data => {
            console.log("facs uc dacs", data)
            setCircleShoppers(data)
            //setCircleShoppers(data => data)  // d=>d breaks state! why?
            // State now has circleShopper records for every
            // circle that shopper belongs to. These records give
            // the shopper visibility to every other shopper in
            // those circles.
            // When shopper joins a new circle, doing fetchCircleShoppers
            // adds a record identifying every other shopper in the circle.
        })
        // .catch(error => {
        //   console.log('fzcs error', error);
        //   throw error;
        // });
    }, []);

    // We'll rebuild state of trips upon signup, signin, /api/me checks
    // and upon addCircleShopper.
    // Some docs: https://www.w3schools.com/react/react_usecallback.asp
    // For each of the shopper's circles, get list of trips
    // TODO: consider setting date ranges to search by,
    // maybe default of trips in last 14 days and all future trips.
    // Hmm, could make that a starting set of constraints.
    // Or, put such search parameters in the UI to filter which trips to see.
    const fetchTrips = useCallback(() => {
      return fetch('/api/trips')  // gets list of trips of circles that shopper is in
        .then(res => res.json())
        .then(data => {
            console.log("fat uc dacs tacs", data)
            setTrips(data)
            return data;
        })
        .catch(error => {
          console.log('fzt error', error);
          throw error;
        });
    }, []);

    // We'll rebuild state of skus upon signup, signin, /api/me checks
    // and upon addCircleShopper.
    // Some docs: https://www.w3schools.com/react/react_usecallback.asp
    // For each of the shopper's circles, get list of skus
    const fetchSkus = useCallback(() => {
        fetch('/api/skus')  // gets list of skus of circles that shopper is in
        .then(res => res.json())
        .then(data => {
            console.log("faskus uc dacs", data)
            setSkus(data)
        })
        // .catch(error => {
        //   console.log('fzs error', error);
        //   throw error;
        // });
    }, []);

    // Some docs: https://www.w3schools.com/react/react_usecallback.asp
    // For each of the shopper's trips, get list of orderItems
    const fetchOrderItems = useCallback(() => {
      console.log("starting faoi")
      return fetch('/api/order_items')  // gets order activity for trips of circles that shopper is in
        .then(res => res.json())
        .then(data => {
            console.log("faoi dacs", data)
            setOrderItems(data)
            return data;
        })
        // .catch(error => {
        //   console.log('fzoi error', error);
        //   throw error;
        // });
    }, []);

    // gets called by fetch('/api/me')
    const fetchInvitations = useCallback(() => {
        fetch('/api/invitations')
        .then(res => res.json())
        .then(data => {
            console.log("fi_", data)
            setInvitations(data)
        })
        // .catch(error => {
        //   console.log('fzi error', error);
        //   throw error;
        // });
    }, []);

    const addFeedback = useCallback((idea) => {
      const configObj = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idea })
      }
      console.log("addFeedback configObj", configObj)

      return fetch(`/api/ideas`, configObj)
      .then(res => res.json())
      .then(data => {
        if (!data.errors) {
          // POST of "idea" object was successful
          console.log("new idea is this: ", data)
        } else {
          includeOtherErrors(data);
          console.log("api_post_idea error(s)", data);
          const errorLis = mkHtmlListOfErrorMessages(...data.errors)

          setErrorsList(_errorsList => [ ..._errorsList, errorLis ]);
          setErrorsDoExist(true);

          const errorString = data.errors.join();
          throw new Error(`api_addFeedback error(s): ${errorString}`);
        }
      })
    //   .catch((error) => console.error(error))
    //   .finally( console.log("All done addFeedback") )
    }, []);
    // if decide to save messages in state then add 'messages' to dependency array

    //useEffect(() => {
    const addCircle = useCallback((circle) => {
        const configObj = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ circle })
        }
        console.log("addCircle configObj", configObj)

        fetch('/api/circles', configObj)
        .then(res => res.json())
        .then(data => {
            if (!data.errors) {
                // POST of "circle" object was successful.

                // Need to collect payment via Stripe.
                // We will set a state variable 'clientReferenceId'
                // with string of id-identifier-country-postal_code
                // This will light up the Stripe button for payment.
                // If leader pays, then 'pending' will change to 'paid'.
                // If payment fails, or they want to pause on paying, they
                // should be able to access the Buy Button via circle update.
                // If they try to make a new circle using the params they
                // already entered, it would be rejected for duplicating
                // values from another (pending) circle.

                // Making a circle w/ free plan sets payment_status to 'paid'.
                // So, don't show Buy Button if plan is a free plan, i.e. 'paid'
                if (! data.is_current) {
                  const clientReferenceIdString = [
                    data.id, data.identifier, data.country, data.postal_code
                  ].join('|');
                  console.log("crIDstring is this: ", clientReferenceIdString);
                  // DEPENDENCY NOTE: On CircleForm, we offer to delete the
                  // new circle by extracting its id from this string.

                  // Setting a value here lights up the Buy Button:
                  setClientReferenceId(clientReferenceIdString);
                }

                // Add new circle to "circles" state.
                console.log("new circle is this: ", data)
                setCircles(c => [...c, data])

                // Here in the frontend, we need to update context to reflect
                // circleShoppers for the new circle. No, we haven't added other
                // shoppers, but when making a new circle, the system made a
                // new circleShopper reflecting that the creator/shopper is a
                // member (and the only member) of the circle.
                // A fetch of all the circle's shoppers will get a one-element
                // array that has the creator. Add this element to state's
                // circleShoppers. (Don't just replace circleShoppers because
                // that would clobber all the circleShopper records for any
                // other circles the shopper belongs to.)
                // TODO: Consider doing a 'fetchCircleShoppers' instead of this
                // circle-based fetch. That would do complete switchout of
                // circleShoppers, i.e. same end result.

                const circle_id = data.id
                console.log("new circle id is this: ", circle_id)
                fetch(`/api/circles/${circle_id}/circle_shoppers`)
                .then(res => res.json())
                .then(data2 => {
                    console.log("new circle, data2 is: ", data2)
                    console.log("new circle, so new circle_shopperb: ", data2[0])
                    if (!data2.errors) {
                        // POST of "circle" object was successful, so update state of "circleShoppers"
                        setCircleShoppers(cs => [...cs, data2[0]])

                        setJustGotClear(true);
                    } else {
                        // e.g. errors out if "check_if_current_shopper_is_in_circle" in controller fails
                        includeOtherErrors(data);
                        console.log("api_post_circle_shoppers error(s)", data2);
                        const errorLis = mkHtmlListOfErrorMessages(...data2.errors)

                        setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                        setErrorsDoExist(true);

                        const errorString = data.errors.join();
                        throw new Error(`api_addCircle CS refresh error(s): ${errorString}`);
                    }
                });

                // As with addSku, not doing 'setJustGotClear' because we don't
                // want success message to show without also knowing that
                // 'addCircleShopper' succeeded.
                //setJustGotClear(x => true);  // now clear in caller w/ setJustGotClearToFalse
            } else {
                //reset()
                includeOtherErrors(data);
                console.log("api_circ", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ]);
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_add_circ error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done addCircle") )
    }, []);

    const editCircle = useCallback((circle) => {
        console.log("editCircle props", circle)
        const configObj = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ circle })
        }
        console.log("editCircle configObj", configObj);

        fetch(`/api/circles/${circle.id}`, configObj)
        .then(res => res.json())
        .then(data => {
            console.log("editCircle init data", data);
            // TODO: not sure "!data.error" needed here. I made it to catch a
            // 500 error raised when circles_controller.rb was kicking out an
            // error. I don't think testing for !data.error actually
            // channeled processing over to the "else" part here.
            if (!data.errors && !data.error) {
                // PATCH of "circle" object was successful, so update "circles"
                const updatedCircles = circles.map((ogCircle) => {
                    if (ogCircle.id === data.id) {
                        return data
                    } else {
                        return ogCircle
                    }
                })
                console.log("updated circles is this: ", updatedCircles);
                setCircles(updatedCircles);
                fetchTrips();
                fetchCircleShoppers();
                fetchInvitations();
                fetchSkus();
                setJustGotClear(true);
            } else {
                includeOtherErrors(data);
                console.log("api_patch_circle error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_edit_circ error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done editCircle") )

    }, [circles, fetchCircleShoppers, fetchInvitations, fetchSkus, fetchTrips]);

    // current_shopper must be the leader of the circle for this to work
    const deleteCircle = useCallback((circle_id) => {
      console.log('circle dc', circle_id)
      const configObj = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      }
      console.log("deleteCircle configObj (check outcome)", configObj);
      console.log("dc before fetch", Date.now() );
      fetch(`/api/circles/${circle_id}`, configObj)
      .then(async res => {
        // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples

        console.log("dc after fetch", Date.now() );
        // check for error response
        if (!res.ok) {
          const data = await res.json();
          console.log("dc after res.json", Date.now() );
          console.log({data});
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          console.log({error});

          includeOtherErrors(data);
          const errorLis = mkHtmlListOfErrorMessages(...data.errors)
          setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
          setErrorsDoExist(true);

          const errorString = data.errors.join();
          throw new Error(`api_delete_circ error(s): ${errorString}`);
          // return Promise.reject(error);
        }

        const data = await res.text();
        console.log("dc after res.text", Date.now() );
        // expecting 'data' to be an empty string - #destroy should return nothing
        console.log('deleteCircle! B', {data})

        // DELETE of "circle" object was successful, so update 'Circles:
        const updatedCircles = circles.filter((ogCircle) => {
          return ogCircle.id !== circle_id
        })

        setCircles(updatedCircles);
        console.log("updated Circles after DELETE is this: ", updatedCircles);

        fetchTrips();
        fetchCircleShoppers();
        fetchInvitations();
        fetchSkus();

        return Promise.resolve('Success!');
      })
    //   .catch(error => console.log('Error message: ', error));
    }, [circles, fetchCircleShoppers, fetchInvitations, fetchSkus, fetchTrips]);

    // "shopper_is_admin" must be true to create 'Join Our Circle' invites.
    // Need to be circle leader to make 'Become Leader' invitations.
    const addInvitation = useCallback((invitation) => {
        console.log('invitation ai', invitation)
        const configObj = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ invitation })
        }
        console.log("addInvitation configObj (check outcome)", configObj)
      return fetch(`/api/circles/${invitation.circle_id}/invitations`, configObj)
          // If the invite was to bogus identifier, we don't want to
          // note that fact by returning an invitation with shopper_id=nil.
          // So, api only sends "header + success" message, no body of json.
        .then(async res => {
          // follows model of deleteInvitation
          console.log("ai after fetch", Date.now() );

          // check for error response
          if (!res.ok) {
            // expecting 'data' to contain error, via json
            const data = await res.json();
            console.log("ai after res.json", Date.now() );
            console.log('ai error block', data);
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            console.log({error});

            includeOtherErrors(data);
            const errorLis = mkHtmlListOfErrorMessages(...data.errors)
            console.log('ai errorLis', errorLis);
            setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
            setErrorsDoExist(true);
            // This should cause shopper's browser to light up with the error.

            const errorString = data.errors.join();
            throw new Error(`api_add_invite error(s): ${errorString}`);
            // return Promise.reject(`api_add_invite error(s): ${errorString}`);
          }

          // else 'res.ok', post of "invitation" object was successful.

          const data = await res.text();
          console.log("ai after res.text", Date.now() );
          // expecting 'data' to be an empty string - #create should return nothing
          console.log('addInvitation! B', {data})

          // There is a circle-specific way to reflect invitations for the
          // circle, via circle serializer method 'open_invites'. For it to
          // reflect the new invite let's do 'fetchCircles'. There won't
          // be much invitation activity, so doesn't seem burdensome.
          fetchCircles();

          return Promise.resolve('Success! Added invitation');
        })
        // .catch(error => console.error('Error message: ', error))
        // .finally( console.log("All done addInvit") );
        // per https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises
        // could ignore error
    }, [fetchCircles]);

    const editInvitation = useCallback((invitation) => {
        console.log("editInvitation props", invitation)
        const configObj = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ invitation })
        }
        console.log("editInvitation configObj", configObj);

      return fetch(`/api/circles/${invitation.circle_id}/invitations/${invitation.id}`, configObj)
        .then(res => res.json())
        .then(data => {
            console.log("editInvitation init data", data);
            if (!data.errors) {
                // PATCH of "invitation" object was successful, so update "invitations"
                const updatedInvitations = invitations.map((ogInvitation) => {
                    if (ogInvitation.id === data.id) {
                        return data
                    } else {
                        return ogInvitation
                    }
                })
                console.log("Invitations now look like this: ", updatedInvitations);
                setInvitations(updatedInvitations);
                fetchCircles();
                setJustGotClear(true);
            } else {
                includeOtherErrors(data);
                console.log("api_patch_invitation error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_patch_invitation error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done edInvit") )

    }, [invitations, fetchCircles]);

    // must have "shopper_is_admin" true for the circle for this to work
    // or else be the shopper in the circle who the invitation is for
    // (and already have accepted the invitation to join the circle.)
    const deleteInvitation = useCallback((invitation_id) => {
        if (!invitation_id) {
            return "No invitation id";
        }

        console.log('invitation di', invitation_id)
        const configObj = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        console.log("deleteInvitation configObj (check outcome)", configObj);
        console.log("di before fetch", Date.now() );
      return fetch(`/api/invitations/${invitation_id}`, configObj)
        .then(async res => {
          // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples
          console.log("di after fetch", Date.now() );

          // check for error response
          if (!res.ok) {
            // expecting 'data' to contain error, via json
            const data = await res.json();
            console.log("di after res.json", Date.now() );
            console.log({data});
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            console.log({error});

            includeOtherErrors(data);
            const errorLis = mkHtmlListOfErrorMessages(...data.errors)
            setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
            setErrorsDoExist(true);

            const errorString = data.errors.join();
            throw new Error(`api_delete_invite error(s): ${errorString}`);
            // return Promise.reject(error);
          }

          const data = await res.text();
          console.log("di after res.text", Date.now() );
          // expecting 'data' to be an empty string - #destroy should return nothing
          console.log('deleteInvitation! B', {data})

          // DELETE of "invitation" object was successful, so update "invitations"
          // Maybe delete is happening due to shopper leaving the circle.
          const updatedInvitations = invitations.filter((origInv) => {
              return origInv.id !== invitation_id
          })
          setInvitations(updatedInvitations);

          // "invitations" is state for outstanding invitations of the
          // current_shopper. If the deleted invitation was for some
          // other shopper, we need to rebuild state for the Circle which
          // had this invitation.
          fetchCircles();

          return Promise.resolve('Success! Deleted ' + invitation_id);
        })
        // .catch(error => console.log('Error message: ', error));
    }, [invitations, fetchCircles]);

    const editCircleShopper = useCallback((circleShopper) => {
        console.log("editCs props", circleShopper)
        const configObj = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ circleShopper })
        }
        console.log("editCs configObj", configObj);

        fetch(`/api/circles/${circleShopper.circle_id}/circle_shoppers/${circleShopper.id}`, configObj)
        .then(res => res.json())
        .then(data => {
            console.log("editCs init data", data);
            if (!data.errors) {
                // PATCH of "circleShopper" object was successful, so update "circleShoppers"
                const updatedCircleShoppers = circleShoppers.map((ogCs) => {
                    if (ogCs.id === data.id) {
                        return data
                    } else {
                        return ogCs
                    }
                })
                console.log("updated CSs is this: ", updatedCircleShoppers);
                setCircleShoppers(updatedCircleShoppers);

                // 'trips' can be source of info about shoppers, but
                // editCircleShoppers is only changing whether one is a
                // circle admin or not.
                // Let's ripple this new info into 'trips' state.
                // TODO: This may not be necessary, so reconsider later.
                fetchTrips();

                // The screen itself here shows success of the toggle by
                // visibly changing yes to no, or no to yes.
                //setJustGotClear(x => true);
            } else {
                // TODO: Did copy/paste of this code. Without thinking
                // about it, esp. with decision to skip showing Success
                // message. So, is the code here sensible?
                includeOtherErrors(data);
                console.log("api_patch_Cs error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_edit_circShopper error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done editCircleShopper") )

    }, [circleShoppers, fetchTrips]);

    const fetchPlans = useCallback(() => {
        fetch('/api/plans')  // gets list of available plans
        .then(res => res.json())
        .then(data => {
            console.log("faplan", data)
            setPlans(data)
        })
        // .catch(error => {
        //   console.log('fzp error', error);
        //   throw error;
        // });
    }, []);


    // We'll rebuild state of prices upon signup, signin, /api/me checks
    // Some docs: https://www.w3schools.com/react/react_usecallback.asp
    // For each of the shopper's circles, get list of skus and their prices
    // FIX ME: I think there is a way to have each sku return price info such
    // that fetchSkus could be doing work of fetchSkus too.
    const fetchPrices = useCallback(() => {
        fetch('/api/prices')  // gets list of prices of skus of circles that shopper is in
        .then(res => res.json())
        .then(data => {
            console.log("faprice", data)  // START HERE: what is this?
            setPrices(data)
        })
        // .catch(error => {
        //   console.log('fzpr error', error);
        //   throw error;
        // });
    }, []);

    const addPrice = useCallback((price) => {
        console.log("sku addPrice props - is sku_id here?", price)

        const configObj = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ price })
        }
        console.log("addPrice configObj", configObj);

        //requires sku_id and shopper_id
        //fetch(`/api/skus/${price.sku_id}/prices`, configObj)
        // This approach will supply :sku_id as a param value with the other params
        fetch('/api/prices', configObj)
        .then(res => res.json())
        .then(data => {
            if (!data.errors) {
                // POST of "price" object was successful, so add to "prices"
                console.log("new price is this: ", data)
                setPrices(p => [...p, data])

                setJustGotClear(true);  // now clear in caller w/ setJustGotClearToFalse
            } else {
                //reset()
                includeOtherErrors(data);
                console.log("api_post_price error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)
                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_addPrice error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done addPrice") )
    }, []);

    const editPrice = useCallback((price) => {
        console.log("editPrice props", price)
        const configObj = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ price })
        }
        console.log("editPrice configObj", configObj);

        fetch(`/api/skus/${price.sku.id}/prices/${price.id}`, configObj)
        .then(res => res.json())
        .then(data => {
            console.log("editPrice init data", data);
            if (!data.errors) {
                // PATCH of "price" object was successful, so update "prices"
                const updatedPrices = prices.map((ogPrice) => {
                    if (ogPrice.id === data.id) {
                        return data
                    } else {
                        return ogPrice
                    }
                })
                console.log("updated prices is this: ", updatedPrices);
                setPrices(updatedPrices);
                setJustGotClear(true);
            } else {
                includeOtherErrors(data);
                console.log("api_patch_price error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_editPrice error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done editPrice") )

    }, [prices]);

    // sort routine from
    // https://www.delftstack.com/howto/javascript/sort-array-based-on-some-property-javascript/
    // TODO: could be extended to sort by value of an attribute parameter
    function s_by_identifier( a, b )
    {
      if ( a.identifier.toLowerCase() < b.identifier.toLowerCase()){
        return -1;
      }
      if ( a.identifier.toLowerCase() > b.identifier.toLowerCase()){
        return 1;
      }
      return 0;
    }

    const addTrip = useCallback((trip) => {
        const configObj = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ trip })
        }
        console.log("addTrip configObj", configObj)

        //fetch(`/api/circles/${trip.circle_id}/trips`, configObj)
        // True, the "circles" form invokes trips#create action just like
        // "/api/trips" does. But rails server notices the ":circle_id"
        // parameter and throws an exception ("Internal Server Error") with msg
        // "#<ArgumentError: wrong number of arguments (given 1, expected 0)>"
        fetch(`/api/trips`, configObj)
        .then(res => res.json())
        .then(data => {
            if (!data.errors) {
                // POST of "trip" object was successful, so add to "trips"
                console.log("new trip is this: ", data)
                setTrips(t => [...t, data])
                setJustGotClear(true);  // now clear in caller w/ setJustGotClearToFalse
            } else {
                //reset()
                includeOtherErrors(data);
                console.log("api_post_trip error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ]);
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_addTrip error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done addTrip") )
    }, []);

    // TODO: logic here is same as editCircle, and close to other 'edit' subs.
    // So, abstract/refactor?
    const editTrip = useCallback((trip) => {
        console.log("editTrip props", trip)
        const configObj = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ trip })
        }
        console.log("editTrip configObj", configObj);

        // fetch(`/api/trips/${trip.id}`, configObj)
        fetch(`/api/circles/${trip.circle_id}/trips/${trip.id}`, configObj)
        .then(res => res.json())
        .then(data => {
            console.log("editTrip init data", data);
            if (!data.errors) {
                // PATCH of "trip" object was successful, so update "trips"
                const updatedTrips = trips.map((ogTrip) => {
                    if (ogTrip.id === data.id) {
                        return data
                    } else {
                        return ogTrip
                    }
                })
                console.log("updated trips is this: ", updatedTrips);
                setTrips(updatedTrips);
                fetchOrderItems();
                setJustGotClear(true);
            } else {
                includeOtherErrors(data);
                console.log("api_patch_trip error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_editTrip error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done editTrip") )

    }, [trips, fetchOrderItems]);

    // current_shopper must be an admin of the circle for this to work
    const deleteTrip = useCallback((trip_id) => {
      console.log('trip dtr', trip_id)
      const configObj = {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
      }
      console.log("deleteTrip configObj (check outcome)", configObj);
      console.log("dtr before fetch", Date.now() );
      fetch(`/api/trips/${trip_id}`, configObj)
      .then(async res => {
        // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples

        console.log("dtr after fetch", Date.now() );
        // check for error response
        if (!res.ok) {
          const data = await res.json();
          console.log("dtr after res.json", Date.now() );
          console.log({data});
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          console.log({error});

          includeOtherErrors(data);
          const errorLis = mkHtmlListOfErrorMessages(...data.errors)
          setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
          setErrorsDoExist(true);

          const errorString = data.errors.join();
          throw new Error(`api_deleteTrip error(s): ${errorString}`);
        //   return Promise.reject(error);
        }

        const data = await res.text();
        console.log("dtr after res.text", Date.now() );
        // expecting 'data' to be an empty string - #destroy should return nothing
        console.log('deleteTrip! B', {data})

        // DELETE of "trip" object was successful, so update:
        // 1) "orderItems"
        // - but doing so generates the error below. The next refresh of state will
        //   not reference any of the trip's orderItems. And we won't be able to get
        //   to them if there is no trip link via state to take us there. Likely fine...
        //   react_devtools_backend_compact.js:2367 Warning: Cannot update a component (`BrowserRouter`) while rendering a different component (`Trip`). To locate the bad setState() call inside `Trip`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
        //     at Trip (http://localhost:4000/static/js/bundle.js:18864:56)
        //     at Routes (http://localhost:4000/static/js/bundle.js:80179:5)
        //     at div
        //     at div
        //     at ShopperProvider (http://localhost:4000/main.c02cf9ca36a7852bf7df.hot-update.js:89:5)
        //     at Suspense
        //     at div

        // console.log("orderItems before DELETE is this: ", orderItems);
        // const updatedOIs = orderItems.filter((ogOI) => {
        //     return ogOI.trip_id !== trip_id
        // })

        // setOrderItems(updatedOIs);
        // console.log("updated orderItems after DELETE is this: ", updatedOIs);


        // and 2) "trips"
        const updatedTrips = trips.filter((ogTrip) => {
            return ogTrip.id !== trip_id
        })

        setTrips(updatedTrips);
        console.log("updated Trips after DELETE is this: ", updatedTrips);
        fetchOrderItems();

        return Promise.resolve('Success!');
      })
    //   .catch(error => console.log('Error message: ', error));
    }, [trips, fetchOrderItems]);

    const addSku = useCallback((sku) => {
        console.log("circle addSku props", sku)
        const configObj = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sku })
        }
        console.log("addSku configObj", configObj);

        fetch(`/api/circles/${sku.circle_id}/skus`, configObj)
        .then(res => res.json())
        .then(data => {
            if (!data.errors) {
                // POST of "sku" object was successful, so add to "skus"
                console.log("new sku is this: ", data)
                setSkus(s => [...s, data])

                // That same "sku" argument has the values necessary
                // to create a new Price object.
                // It had extaraneous values upon creating Sku record:
                // rails console shows: Unpermitted parameters: [:shopper_id - rm'd],
                // :currency, :price_date, :discount_amount, :discount_end_date,
                // :discount_start_date,
                // :price, :price_with_tax, :unit_price
                // Those values are the ones applicable for the Price record.
                //
                // Add the new sku_id, accessible via "data:id", to the "sku"
                // argument we started with so that "addPrice" can serve up a
                // viable POST body.
                addPrice({ ...sku, sku_id: data.id })
                // Rm'd column shopper_id, so, no: Also need shopper_id, can add it too.
                //addPrice({ ...sku, sku_id: data.id, shopper_id: shopper.id })

                // We could strip out the "sku"-based attributes to avoid the
                // "Unpermitted parameters" error we'll get, but nah for now.

                // not doing 'setJustGotClear' because we don't want success message to
                // show without also knowing that 'addPrice' succeeded.
                // Here, we are not checking for addPrice errors (data.errors):
                // We check within 'const addPrice'!
                // TODO: So, wait til that succeeds before indicating success.
                // But addSku succeeded. Shouldn't the shopper know about the success?
                // Maybe its success should be wrapped in a transaction that rolls back
                // the addSku if price does not also succeed.
                // setJustGotClear(x => true);  // now clear in caller w/ setJustGotClearToFalse
            } else {
                //reset()
                includeOtherErrors(data);
                console.log("api_post_sku error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_addSku error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done addSku") )
    }, [addPrice]);

    const searchSkus = useCallback((query) => {
      console.log('sku search', query)
      const configObj = {
          headers: { 'Content-Type': 'application/json' }
      }
      console.log("searchSku configObj (check outcome)", configObj);
      console.log("search before fetch", Date.now() );
      return fetch(`/api/search_skus?${query}`, configObj)
      .then(async res => {
        // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples

        console.log("search after fetch", Date.now() );
        // check for error response
        if (!res.ok) {
          const data = await res.json();
          console.log("search after failed res.json", Date.now() );
          console.log({data});
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          console.log({error});

          includeOtherErrors(data);
          const errorLis = mkHtmlListOfErrorMessages(...data.errors)
          setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
          setErrorsDoExist(true);

          const errorString = data.errors.join();
          throw new Error(`api_searchSku error(s): ${errorString}`);
        //   return Promise.reject(error);
        }

        const data = await res.json();
        console.log("search after successful res.json", Date.now() );
        console.log('searchSku! B', {data})

        // Remove the leading uuid value from each 'detail' attr and
        // set it as its own attr.
        const newData = data.map( search_sku => {
          const search_sku_attrs = {};
          for (const property in search_sku) {
            // avoid taking on attributes with null or undefined values
            if (search_sku[property]) {
              search_sku_attrs[property] = search_sku[property];
            }
          }

          const sku_detail = search_sku.detail.substring(36);
          const uuuid = search_sku.detail.substring(0, 36);

          search_sku_attrs['detail'] = sku_detail;
          search_sku_attrs['uuuid'] = uuuid;

          return search_sku_attrs;
        })
        console.log('searchSku! C', {newData})

        setFoundSkus(newData);

        //return Promise.resolve('Success!');
        return Promise.resolve(newData);
      })
    //   .catch(error => console.log('Error message: ', error));
    }, []);

    const addOrderItem = useCallback((orderItem) => {
        console.log('orderItem acs', orderItem)

        const configObj = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ orderItem })
        }
        console.log("addOrderItem configObj", configObj);

        return fetch('/api/order_items', configObj)
        .then(res => res.json())
        .then(data => {
            console.log('addOrderItem! ', data)
            if (!data.errors) {
                // post of "orderItem" object was successful, so add to "orderItems"
                console.log('success addOrderItem! ')
                setOrderItems(oi => [...oi, data])
                // The change seems to take effect because we don't need to do
                // 'fetchOrderItems()' in SkuMenuLink after adding all the
                // orderItems for the shopper and other circleShoppers.

                // 'trips' can be source of info about orderItems, so we must
                // ripple this new info into 'trips' state.
                fetchTrips();
            } else {
                //reset()
                includeOtherErrors(data);
                console.log("api_circshop", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_addOrderItem error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done addOrderItem") )
    }, [fetchTrips]);

    // Hmm - this could be a policy (but):
    // if someone is dropping to zero, and all others are at zero,
    // then remove this sku from everyone else's list.
    // Maybe leave it alone. People often don't like having things taken
    // from them, even zero-item things. We could provide a "delete" checkbox
    // which wipes it out. They can monitor group ordering interest in the
    // item for more perspective on likelihood of "go".

    const changeOrderItem = useCallback((orderItem, order_id = 0, showJustGotClear = false) => {
        console.log('changeOrderItem oi', order_id)
        console.log('changeOrderItem oI', orderItem)

        const configObj = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ orderItem })
        }
        const oid = order_id || orderItem.id;
        fetch(`/api/order_items/${oid}`, configObj)
        .then(res => res.json())
        .then(data => {
            if (!data.errors) {
                // patch of "orderItem" object was successful,
                console.log('success chgOrderItem!')
                // so reflect in state's "orderItems"
                console.log('chgOrderItem! ', data)
                // create a list of all the order items that didn't change
                //const nonOiOrders = orderItems.filter(oi => oi.id != order_id)
                const nonOiOrders = orderItems.filter(oi => oi.id != oid)
                // add the updated order_item record to the list of unchanged records
                setOrderItems([...nonOiOrders, data])
                // console.log('result of chgOrderItem! ', orderItems)
                //navigate('/')

                // 'trips' can be source of info about orderItems, so we must
                // ripple this new info into 'trips' state.
                fetchTrips();

                // We don't want success message when shopper edits their own sku quantities.
                // We want the success confirmation when admin edits a shopper's sku amount:
                if (showJustGotClear) {
                    setJustGotClear(true);
                }
            } else {
                //reset()
                includeOtherErrors(data);
                console.log("api_oi_error", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_changeOrderItem error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done changeOrderItem") )
    }, [orderItems, fetchTrips]);
    //const handleOrderItemChange = (orderItem) => {
    //}

    const deleteOrderItems = useCallback((circle_id, orderItem_ids) => {
      if (!orderItem_ids) {
          return "No orderItem ids";
      }

      console.log('orderItems doi', orderItem_ids)
      const configObj = {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
      }
      console.log("deleteOrderItems configObj (check outcome)", configObj);
      console.log("doi before fetch", Date.now() );
      return fetch(`/api/circles/${circle_id}/order_items/${orderItem_ids}`, configObj)
      .then(async res => {
        // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples
        console.log("doi after fetch", Date.now() );

        // check for error response
        if (!res.ok) {
          // not ok, so expecting 'data' to contain error, via json
          const data = await res.json();
          console.log("doi after res.json", Date.now() );
          console.log({data});
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          console.log({error});

          includeOtherErrors(data);
          const errorLis = mkHtmlListOfErrorMessages(...data.errors)
          setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
          setErrorsDoExist(true);

          const errorString = data.errors.join();
          alert(`Error when deleting OrderItems: ${errorString}`);
          throw new Error(`deleteOrderItem error(s): ${errorString}`);
          //return Promise.reject(errorString);
          // TODO: do we need to 'catch' this somewhere?
        }

        const data = await res.text();
        console.log("doi after res.text", Date.now() );
        // expecting 'data' to be an empty string - #destroy should return nothing to us
        console.log('deleteOrderItems! B', {data})

        // DELETE of "orderItems" object was successful, so update "orderItems"
        // by removing all OI objects with ids matching ids we deleted.
        const orderItemIdsArray = orderItem_ids.split(',');  // an array of the deleted ids
        console.log("doi orderItemIdsArray", orderItemIdsArray );
        console.log("doi orderItems", orderItems );
        const updatedOrderItems = orderItems.filter((origOI) => {
            return !orderItemIdsArray.includes(origOI.id + '')
        })
        console.log("doi updatedOrderItems", updatedOrderItems );

        setOrderItems(updatedOrderItems);
        // TODO: Fix me: The change doesn't stick.
        // For a one-element array of orderItemIdsArray, we produce an
        // array that has one element less than orderItems. Fine, we do:
        // setOrderItems(updatedOrderItems);
        // but upon resetting state for the next item, we are back to
        // removing that next item from the full/original orderItems.
        // ==> The change doesn't stick.
        // And console.log of orderItems shows the original array, not
        // a reduced array.
        // console.log("doi updated orderItems after DELETE: ", orderItems);
        //
        // also fails:
        // orderItemIdsArray.forEach(oiId => {
        //   const index = orderItems.findIndex(origOI => origOI.id + '' === oiId)
        //   setOrderItems(
        //     orderItems =>
        //       [...orderItems.slice(0,index) ,
        //       ...orderItems.slice(index+1,orderItems.length)]
        //   );
        // })

        fetchTrips();

        //return Promise.resolve('Success!');
        return ('Success!');
      })
      //.catch(error => console.log('Error message: ', error));
    }, [orderItems, fetchTrips]);

    // To try to get each individual 'setOrderItems' to fire immediately, but...
    // useEffect(() => { console.log(orderItems) }, [orderItems])

    // attributes :id, :abs_amount
    // has_one :trip
    // has_one :sku
    // has_one :shopper

    const editSku = useCallback((sku) => {
        console.log("editSku props", sku)
        const configObj = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ sku })
        }
        console.log("editSku configObj", configObj);
        // 'shareable' has values like '0' and '1'

      return fetch(`/api/circles/${sku.circle_id}/skus/${sku.id}`, configObj)
        .then(res => res.json())
        .then(data => {
            if (!data.errors) {
                console.log("updated sku is this: ", data);
                // 'shareable' comes back with values like 'false' and 'true'
                // PATCH of "sku" object was successful, so update "skus"
                const updatedSkus = skus.map((ogSku) => {
                    if (ogSku.id === data.id) {
                        return data
                    } else {
                        return ogSku
                    }
                })
                console.log("updatedSkus is this: ", updatedSkus);
                setSkus(updatedSkus);
                fetchPrices();
                fetchOrderItems();
                setJustGotClear(true);
            } else {
                includeOtherErrors(data);
                console.log("api_patch_sku error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_editSku error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done editSku") )

    }, [skus, fetchPrices, fetchOrderItems]);

    // current_shopper must be an admin of the circle for this to work
    const deleteSku = useCallback((sku_id) => {
      console.log('sku dsk', sku_id)
      const configObj = {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
      }
      console.log("deleteSku configObj (check outcome)", configObj);
      console.log("dsk before fetch", Date.now() );
      fetch(`/api/skus/${sku_id}`, configObj)
      .then(async res => {
        // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples

        console.log("dsk after fetch", Date.now() );
        // check for error response
        if (!res.ok) {
          const data = await res.json();
          console.log("dsk after res.json", Date.now() );
          console.log({data});
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          console.log({error});

          includeOtherErrors(data);
          const errorLis = mkHtmlListOfErrorMessages(...data.errors)
          setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
          setErrorsDoExist(true);

          const errorString = data.errors.join();
          throw new Error(`api_deleteSku error(s): ${errorString}`);
        //   return Promise.reject(error);
        }

        const data = await res.text();
        console.log("dsk after res.text", Date.now() );
        // expecting 'data' to be an empty string - #destroy should return nothing
        console.log('deleteSku! B', {data})

        // DELETE of "sku" object was successful, so update "skus"
        const updatedSkus = skus.filter((ogSku) => {
            return ogSku.id !== sku_id
        })

        setSkus(updatedSkus);
        console.log("updated Skus after DELETE is this: ", updatedSkus);
        fetchPrices();
        fetchOrderItems();

        return Promise.resolve('Success!');
      })
    //   .catch(error => console.log('Error message: ', error));
    }, [skus, fetchPrices, fetchOrderItems]);

    // must have:
    //   current_shopper has invitation to circle with status of 'Accepted'
    // for the circle for this to work
  const addCircleShopper = useCallback((circleShopper) => {
    console.log('circleShopper acs dacs', circleShopper)
    console.log('before doing addCircleShopper dacs', circleShoppers)

    const configObj = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ circleShopper })
    }
    console.log("addCircleShopper configObj (check outcome)", configObj)

    // Shopper is joining a circle, so we need to identify that
    // circle's trips and the skus of orderItems for those trips
    // so that we can make zero-amount orderItems for those
    // same skus for the new shopper.
    const cs_shopper_id = circleShopper.cs_shopper_id;
    console.log('acs cs_shopper_id', cs_shopper_id);

    fetch(`/api/circles/${circleShopper.circle_id}/circle_shoppers`, configObj)
    .then(res => res.json())
    .then(data => {
      if (!data.errors) {
        // POST of "cs" object was successful, so refresh "circleShoppers"
        console.log("new cs is this:  dacs tacs", data)
        buildOutCircleShopper();
      } else {
        includeOtherErrors(data);
        console.log("api_post_CS error(s)", data);
        const errorLis = mkHtmlListOfErrorMessages(...data.errors)

        setErrorsList(_errorsList => [ ..._errorsList, errorLis ]);
        setErrorsDoExist(true);

        const errorString = data.errors.join();
        throw new Error(`api_addCS error(s): ${errorString}`);
      }
    })

    // fetchCircleShoppers()
    // When shopper first joins a circle, the shopper has no
    // circleShopper records yet for that circle, so doing
    // setCircleShoppers((circleShoppers) => [...circleShoppers, data])
    // only adds the first cs record to the state, not the whole
    // collection of other shoppers' cs records for that circle.
    // To fully inflate 'circleShoppers', we need to call the
    // database to get those other circleShopper records for
    // the circle the shopper has just joined.

    // fetchCircles()
    // The db now has a cs record pointing to the circle!
    // Rebuild Circle data in state so that the list of shopper's
    // circles includes this circle. 

    // fetchSkus(), fetchPrices()
    // The new circle will have its own set of skus, so pull that
    // sku info down for the new circleShopper. And price info.

    // fetchTrips()
    // Rebuild Trip data in state so that the list of shopper's
    // trips includes this circle's trips. This works because the
    // list of trips keys off of shopper's circles. Shopper's circles
    // are based on circleShopper records, and we just made a cs
    // record in the db for the shopper, so any trips of the circle
    // will return via fetchTrips.

    // fetchOrderItems()
    // Given the shopper's new trips based on the new circle, we need
    // to update state to reflect the OrderItems of the circle's other
    // circleShoppers. Assuming any exist, we will then create 0-amt
    // OrderItems in the DB for the new shopper for the active skus
    // of the trips.

    async function buildOutCircleShopper() {
      // Wait for the six fetches in parallel.
      const [fzCircleShopper, fzCircles, fzSkus, fzPrices, fzTrips, fzOrderItems]
        = await Promise.all([
          fetchCircleShoppers(),
          fetchCircles(),
          fetchSkus(),
          fetchPrices(),
          fetchTrips(),
          fetchOrderItems(),
        ]);
      console.log('fz all dacs tacs',
        {fzCircleShopper}, {fzCircles}, {fzSkus}, {fzPrices}, {fzTrips}, {fzOrderItems})
      // Some fetches above do not return data, so those objs show 'undefined'

      // can/should we do something like this here?
      // await setJustGotClear(x => true);

      // We don't have access to the new info simply by state as per:
      //   console.log('ue orderItems state dacs', orderItems) // shows info pre-fetch
      //   console.log('ue trips state dacs tacs', trips)      // shows info pre-fetch

      mkZeroAmtOrderItems(fzTrips, fzOrderItems, cs_shopper_id, circleShopper.circle_id);

      return 'parallel fetches done!';
    }

    function mkZeroAmtOrderItems (trip_dat, oi_dat, shoppersId, shoppersCircleId) {
      shoppersId ||= cs_shopper_id;  // shopper_id of the person joining the circle
       console.log('mzqoi shoppersId tacs', shoppersId)

      shoppersCircleId ||= circleShopper.circle_id;
      // Circle the shopper is joining, per the newly added circleShopper record.
      // This enables matching on trips of the circle.
      console.log('mzqoi shoppersCircleId tacs', shoppersCircleId)

      // array of shopper's trips, *including trips of the new circle
      console.log('mzqoi trips dat dacs tacs', trip_dat)

      // array of shopper's OIs, *including OIs of the new set of trips
      console.log('mzqoi orderItems dat dacs tacs', oi_dat)

      // To filter which trips get 0-amt OIs by date ("today or later"),
      // we need today's date in yyyy-mm-dd format,
      // see: https://stackoverflow.com/a/63490548
      // Canada (CA) has this format, 2023-04-21, don't think US does, so:
      const todaysDate = new Date().toLocaleDateString('en-CA')
      console.log('todaysDate acs', todaysDate)
      // TODO: This is open to UTC issues...
      // Saving grace: If it's nighttime in the US when this runs
      // and we get tomorrow's date, it's likely a good thing that
      // orderItems from today's trips will not be copied.
      // (They are in the past now!)
      // => Should this be 'hour'-sensitive too?

      trip_dat
        .filter(trip => trip.circle.id == shoppersCircleId)
        //.filter(trip => trip.day >= todaysDate)

        // TODO: consider: Theoretically, a new circleShopper has no need to see
        // trip data from before when shopper was not a circleShopper. As such,
        // we could call a different api fetch such as 'fetchFutureTrips'. But,
        // hmm, future logins will/would still call the regular 'fetchTrips'.
        // Maybe it needs a qualifier such as (pseudocode):
        // trip.day >= circleShopper.created_at
        // But, maybe this should be handled from the Rails end, where we
        // wouldn't provide trip data for tripdates < circleShopper.created_at
        // For now, Rails provides all of a Circle's Trip info and we use
        // front-end filters to limit the scope of creating zero-amt OIs.

        .filter(trip => ! trip.is_hidden)
        .forEach(trip => {
          // TODO: is 'forEach' the best tool here?
          console.log('mzqoi trip tacs', trip)

          // If shopper has an OI for this trip then we can assume
          // shopper has the whole set of zero-amt OIs for the trip.
          // (Or, we could filter sku_ids for whether an OI already exists
          // for this trip.)
          // We might expect to see such OIs on React's second run through
          // this same code during development compiles. The first pass
          // should have already made the OI and updated state.
          // But interesting: I'm not seeing repeat behavior in
          // console.log records.
          // We might also see such OIs if this gets used for maintenance,
          // i.e. to fill in gaps that somehow exist, but that's a futyre.
          if (oi_dat.find(
              (oi) => oi.trip.id == trip.id && oi.shopper.id == shoppersId)
            ) {
            return;  // move on to the next trip in forEach
          }

          trip.sku_ids.forEach(sku_id => {
            const orderItem = {
              shopper_id: shoppersId,
              sku_id: sku_id,
              trip_id: trip.id,
              abs_amount: 0
            };
            console.log("orderItemMZQOI tacs", orderItem)
            addOrderItem(orderItem);
          })
        })  // end of forEach trip loop
      }  // end of mkZeroAmtOrderItems

    }, [
      circleShoppers,
      fetchCircleShoppers,
      fetchCircles,
      fetchSkus,
      fetchPrices,
      fetchTrips,
      fetchOrderItems,
      addOrderItem,
    //   trips,
    //   orderItems,
    ]);

    // current_shopper must be leader of the circle or else the
    // shopper_id of the circleShopper for this to work
    const deleteCircleShopper = useCallback((circleShopper_id) => {
        console.log('circleShopper rcs', circleShopper_id)
        const configObj = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        console.log("deleteCircleShopper configObj (check outcome)", configObj);
        console.log("rcs before fetch", Date.now() );
      return fetch(`/api/circle_shoppers/${circleShopper_id}`, configObj)
        .then(async res => {
          // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples

          console.log("rcs after fetch", Date.now() );
          // check for error response
          if (!res.ok) {
            const data = await res.json();
            console.log("rcs after res.json", Date.now() );
            console.log({data});
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            console.log({error});

            includeOtherErrors(data);
            const errorLis = mkHtmlListOfErrorMessages(...data.errors)
            setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
            setErrorsDoExist(true);

            const errorString = data.errors.join();
            throw new Error(`api_deleteCircleShopper error(s): ${errorString}`);
            // return Promise.reject(error);
          }

          const data = await res.text();
          console.log("rcs after res.text", Date.now() );
          // expecting 'data' to be an empty string - #destroy should return nothing
          console.log('deleteCircleShopper! B', {data})

          // DELETE of "circleShopper" object was successful, so update "circleShoppers"
          const updatedCircleShoppers = circleShoppers.filter((ogCs) => {
              return ogCs.id !== circleShopper_id
          })

          setCircleShoppers(updatedCircleShoppers);
          console.log("updated CSs after DELETE is this: ", updatedCircleShoppers);

          // Rebuild Circle data in state so that the list of shopper's circles
          // no longer includes this circle. The db no longer has such cs record!
          fetchCircles();

          // TODO: Might be nice to return a value from here, but none of these
          // three seem to return a value to a non-context call of this function:
          // return Promise.resolve('Success!');
          // Promise.resolve('Success!');
          return 'Success!';
        })
        // .catch(error => console.log('Error message: ', error));
    }, [circleShoppers, fetchCircles]);

    const editShopper = useCallback((shopper) => {
        console.log("editShopper props", shopper)
        const configObj = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ shopper })
        }
        console.log("editShopper configObj", configObj);

        fetch(`/api/shoppers/${shopper.id}`, configObj)
        .then(res => res.json())
        .then(data => {
            console.log("editShopper init data", data);
            if (!data.errors) {
                // PATCH of "shopper" object was successful, so update "shopper"
                setShopper(data);
                fetchCircleShoppers();
                fetchOrderItems();
                fetchInvitations();
                fetchTrips();
                setJustGotClear(true);
            } else {
                includeOtherErrors(data);
                console.log("api_patch_shopper error(s)", data);
                const errorLis = mkHtmlListOfErrorMessages(...data.errors)

                setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
                setErrorsDoExist(true);

                const errorString = data.errors.join();
                throw new Error(`api_editShopper error(s): ${errorString}`);
            }
        })
        // .catch((error) => console.error(error))
        // .finally( console.log("All done editShopper") )

    }, [fetchCircleShoppers, fetchOrderItems, fetchInvitations, fetchTrips]);

    // current_shopper must be the shopper for this to work
    const deleteShopper = useCallback((shopper_id) => {
      console.log('shopper dsh', shopper_id)
      const configObj = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      }
      console.log("deleteShopper configObj (check outcome)", configObj);
      console.log("dsh before fetch", Date.now() );
      return fetch(`/api/shoppers/${shopper_id}`, configObj)
      .then(async res => {
        // per https://jasonwatmore.com/post/2021/09/21/fetch-http-delete-request-examples

        console.log("dsh after fetch", Date.now() );
        // check for error response
        if (!res.ok) {
          const data = await res.json();
          console.log("dsh after res.json", Date.now() );
          console.log({data});
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          console.log({error});

          includeOtherErrors(data);
          console.log('data_errs2', data.errors);
          const errorLis = mkHtmlListOfErrorMessages(...data.errors)
          setErrorsList(_errorsList => [ ..._errorsList, errorLis ])
          setErrorsDoExist(true);

          // We don't signal the caller of 'deleteShopper' about errors by
          // doing 'return Promise.reject(error)' and then doing '.catch' below.
          // Instead, throw error here, and, by not catching, this ends this
          // function and also halts the function where this was called.
          // Since we have built up 'errorsList' in state and set the boolean,
          // the frontend can still show the error(s).
          const errorString = data.errors.join();
          // alert(`Error when deleting Shopper: ${errorString}`);
          throw new Error(`deleteShopper error(s): ${errorString}`);
        }

        const data = await res.text();
        console.log("dsh after res.text", Date.now() );
        // expecting 'data' to be an empty string - #destroy should return nothing
        console.log('deleteShopper! B', {data})

        // DELETE of "shopper" object was successful,
        // so need to sign out (or kick out). Go to Sign-in page?

        return Promise.resolve('Success!');
      })
      // .catch(error => console.log('Error message: ', error));
    }, []);


    const signin = useCallback((cShopper) => {
        console.log("ct_signin", cShopper);
        setShopper(cShopper)
        fetchCircles()
        fetchInvitations()
        fetchCircleShoppers()
        fetchSkus()
        fetchPrices()
        fetchPlans()
        fetchTrips()
        fetchOrderItems()
        setSignedIn(true)
    }, [
        fetchCircles,
        fetchInvitations,
        fetchCircleShoppers,
        fetchSkus,
        fetchPrices,
        fetchPlans,
        fetchTrips,
        fetchOrderItems,
    ]);

        //console.log("ct_signout", shopper);
    const signout = useCallback(() => {
        setShopper(null)
        setCircles([])
        setInvitations([])
        setCircleShoppers([])
        setSkus([])
        setPrices([])
        setPlans([])
        setTrips([])
        setOrderItems([])
        setSignedIn(false)
        setShowFeedbackForm(false);
        setSpAuthIsActive(false);
        setStripeIsActive(false);
        setStripeIsLive(false);
        setCircle({});
        setClientReferenceId('');
        setLastTripSeenId('');
        setShowShoppingList(true);
        setInSkuMenu(false);
        setFoundSkus([]);
        setLastSkuSeen('');
        setShowOutlayWithTax(false);
        setShowOthersOrders(false);
        setShowOthersZeroOrders(false);
        setErrorsList([]);
        setErrorsDoExist(false);
        setJustGotClear(false);
    }, []);

    const signup = useCallback((cShopper) => {
        console.log("ct_signup", cShopper);
        setShopper(cShopper)
        fetchCircles()
        fetchInvitations()
        fetchCircleShoppers()
        fetchSkus()
        fetchPrices()
        fetchPlans()
        fetchTrips()
        fetchOrderItems()
        setSignedIn(true)
    }, [
        fetchCircles,
        fetchInvitations,
        fetchCircleShoppers,
        fetchSkus,
        fetchPrices,
        fetchPlans,
        fetchTrips,
        fetchOrderItems,
    ]);
        // TODO: later, let's require use of shopper's email as condition for first signin
        // password reset function via email
        // https://pascales.medium.com/welcome-email-for-new-user-using-action-mailer-becdb43ee6a
        // https://medium.com/binar-academy/forgot-password-feature-on-rails-api-8e4a7368c59

        // And someone comments re master key usage in Rails 5.2 and later:
        // https://medium.com/@leslie.sage/from-secrets-to-credentials-how-to-update-a-pre-5-2-rails-app-aa63fbb6281

        // Maybe simplest:
        // https://jjasghar.github.io/blog/2014/05/02/updating-a-rails-password-via-the-rails-console/

    console.log("init_fetch_me", shopper);

    // In cases like shopper deleting self, need to return control to Home pg.
    // Can't do "navigate('/')" in here so allow that function to be passed in.
    const signoutShopperPlus = useCallback((doPlus) => {
        fetch('/api/signout', {
            method: 'DELETE'
            // , headers: { 'Content-Type': 'application/json' }  // don't need
        })
        .then(() => {
            doPlus();  // navigate('/')
            signout();
        })
    }, [signout]);

    useEffect(() => {
        fetch('/api/me')  // checks for if there's a shopper in the session hash
        .then(res => res.json())
        .then(data => {
            console.log("ct_fetch_me", data);
            if (data.errors) {
                setSignedIn(false)
                // TODO: do something with the error(s)?
            } else {
                setShopper(data)
                setSignedIn(true)
                fetchCircles()
                fetchSkus()
                fetchPrices()
                fetchPlans()
                fetchTrips()
                fetchOrderItems()
                fetchInvitations()
                fetchCircleShoppers()
            }
        })
    }, [fetchCircles, fetchInvitations, fetchCircleShoppers, fetchSkus, fetchPrices, fetchPlans, fetchTrips, fetchOrderItems]);
            // TODO: check on error vs errors - what's going on?

    const memoValue = useMemo(() => ({
            shopper, editShopper, signin, signout, signup, signedIn,
            signoutShopperPlus,
            deleteShopper,
            addFeedback, showFeedbackForm, setShowFeedbackForm,
            latestPausedText, resetLatestPausedText,
            spAuthIsActive,
            stripeIsActive, stripeIsLive,
            lastTripSeenId, setLastTripSeenId,
            showShoppingList,
            setShowShoppingListToTrue, setShowShoppingListToFalse,
            inSkuMenu, setInSkuMenuToTrue, setInSkuMenuToFalse,

            circles, addCircle, editCircle,
            clientReferenceId, setClientReferenceId,
            deleteCircle,
            fetchCircles,
            fetchCircleOrders,

            invitations, addInvitation, editInvitation,
            deleteInvitation,

            circleShoppers, addCircleShopper, // fetchCircleShoppers,
            editCircleShopper,
            deleteCircleShopper,

            skus, addSku, editSku, fetchSkus,
            deleteSku,
            searchSkus, foundSkus, unsetFoundSkus,
            lastSkuSeen, setLastSkuSeen,

            prices, addPrice, editPrice, // fetchPrices,
            plans, // fetchPlans,

            trips, addTrip, editTrip, fetchTrips,
            deleteTrip,
            //callFetchTrips,

            orderItems, addOrderItem, fetchOrderItems,
            toggleShowOutlayWithTax,
            showOutlayWithTax,
            changeOrderItem,
            deleteOrderItems,
            toggleShowOthersOrders,
            showOthersOrders,
            toggleShowOthersZeroOrders,
            showOthersZeroOrders,

            //checkedItemsByCircle, itemsAreOn,
            errorsList, dismissErrorsList, addToErrorsList,
            errorsDoExist, setErrorsDoExistToFalse, setErrorsDoExistToTrue,

            justGotClear, setJustGotClearToFalse
            //justGotClear, setJustGotClearToFalse, setJustGotClearToTrue
     }), [
            shopper, editShopper, signin, signout, signup, signedIn,
            signoutShopperPlus,
            deleteShopper,
            addFeedback, showFeedbackForm, setShowFeedbackForm,
            latestPausedText, resetLatestPausedText,
            spAuthIsActive,
            stripeIsActive, stripeIsLive,
            lastTripSeenId, setLastTripSeenId,
            showShoppingList,
            setShowShoppingListToTrue, setShowShoppingListToFalse,
            inSkuMenu, setInSkuMenuToTrue, setInSkuMenuToFalse,

            circles, addCircle, editCircle,
            clientReferenceId, setClientReferenceId,
            deleteCircle,
            fetchCircles,
            fetchCircleOrders,

            invitations, addInvitation, editInvitation,
            deleteInvitation,

            circleShoppers, addCircleShopper, // fetchCircleShoppers,
            editCircleShopper,
            deleteCircleShopper,

            skus, addSku, editSku, fetchSkus,
            deleteSku,
            searchSkus, foundSkus, unsetFoundSkus,
            lastSkuSeen, setLastSkuSeen,

            prices, addPrice, editPrice, // fetchPrices,
            plans, // fetchPlans,

            trips, addTrip, editTrip, fetchTrips,
            deleteTrip,
            //callFetchTrips,

            orderItems, addOrderItem, fetchOrderItems,
            toggleShowOutlayWithTax,
            showOutlayWithTax,
            changeOrderItem,
            deleteOrderItems,
            toggleShowOthersOrders,
            showOthersOrders,
            toggleShowOthersZeroOrders,
            showOthersZeroOrders,

            //checkedItemsByCircle, itemsAreOn,
            errorsList, dismissErrorsList, addToErrorsList,
            errorsDoExist, setErrorsDoExistToFalse, setErrorsDoExistToTrue,

            justGotClear, setJustGotClearToFalse
            // justGotClear, setJustGotClearToFalse, setJustGotClearToTrue
     ]);

    return (
        // <ShopperContext.Provider value={{ memoValue }} >
        // I don't remember adding the double {{}} effect for memoValue
        // so it must have been there before. But having the {{}} approach
        // seemed to be a problem for accessing children, so I slimmed it
        // to {memoValue}. Seems to work. Huh.
        <ShopperContext.Provider value={ memoValue } >
            {children}
        </ShopperContext.Provider>
    );
}

export { ShopperContext, ShopperProvider };
